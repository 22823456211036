import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import {} from "google-maps";
// declare  global {
//   interface Window {
//     initMap: () => void;
//   }
// }
@Component({
  selector: "app-add-address",
  templateUrl: "./add-address.component.html",
  styleUrls: ["./add-address.component.scss"],
})
export class AddAddressComponent implements OnInit, AfterViewInit {
  emiratesData: Object;
  emiratesId;
  areaData: any;
  areaId: any;
  addressFrom;
  latitude = 25.2048;
  longitude = 55.2708;
  locationChosen = false;
  addresstypes = "Home";
  isDefault: boolean = false;
  ngLoader = false;
  autoAddress: any = "";
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild("addresstext") addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;

  onChoseLocation(event) {
    console.log("LAT:" + event.coords.lat);
    console.log("LON:" + event.coords.lng);

    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.locationChosen = true;
  }
  constructor(
    private fb: FormBuilder,
    private ApiService: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.addressFrom = this.fb.group({
      emirates: ["", [Validators.required]],
      area: ["", [Validators.required]],
      addressLine: ["", [Validators.required]],
      addressType: ["Home", []],
      autoAddress: ["", [Validators.required]],
      isDefault: [""],
    });
  }
  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
  }
  private getPlaceAutocomplete() {
    // console.log( );

    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        componentRestrictions: { country: "AE" },
        types: [this.adressType], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      this.autoAddress = place.name;
      console.log(place.name);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();
      this.locationChosen = true;
      console.log(this.latitude);
      console.log(this.longitude);

      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
  get f() {
    return this.addressFrom.controls;
  }

  addressType(event: any) {
    console.log(event.target.defaultValue);
    this.addresstypes = event.target.defaultValue;
  }

  autoInputChange(val: any) {
    console.log(val);
  }

  onSubmit() {
    const address_body = {
      emiratesId: this.addressFrom.value.emirates,
      deliveryAreaId: this.addressFrom.value.area,
      addressLine: this.addressFrom.value.addressLine,
      latitide: this.latitude,
      longitude: this.longitude,
      autoAddress: this.autoAddress,
      addressType: this.addressFrom.value.addressType,
      isDefault: this.addressFrom.value.isDefault,
    };
    this.ngLoader = true;
    this.ApiService.addAddress(address_body).subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          const object = {
            id: res.addressId,
            addressType: res.addressType,
            emirates: res.emirateName,
            area: res.deliveryAreaName,
            line: this.addressFrom.value.addressLine,
            emirateId: this.addressFrom.value.emiratesId,
            isDefault: this.addressFrom.value.isDefault,
          };
          console.log("Address obj is ");
          console.log(object);
          this.ApiService.setLocalStorageItem(
            "deliveryAddress",
            JSON.stringify(object)
          );

          this.ApiService.goTo("order-details", this.router);
          this.toastr.success(res.message);
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {}
    );
  }

  handleEmptyCart() {
    var cartItems = this.ApiService.getLocalStorageItem("cartItem");
    var isCartEmpty = true;
    if (cartItems) {
      cartItems = JSON.parse(cartItems);
      if (cartItems.length) {
        isCartEmpty = false;
      }
    }
    if (isCartEmpty) {
      this.ApiService.goTo("cart", this.router);
    }
  }
  init() {
    this.handleLogin();
    this.handleEmptyCart();
    this.handleEmiratesList();
  }
  ngOnInit() {
    // This example requires the Places library. Include the libraries=places
    // parameter when you first load the API. For example:
    // <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">

    // function initMap(): void {
    //   const map = new google.maps.Map(
    //     document.getElementById("map") as HTMLElement,
    //     {
    //       center: { lat: 40.749933, lng: -73.98633 },
    //       zoom: 13,
    //       mapTypeControl: false,
    //     }
    //   );
    //   const card = document.getElementById("pac-card") as HTMLElement;
    //   const input = document.getElementById("pac-input") as HTMLInputElement;
    //   const biasInputElement = document.getElementById(
    //     "use-location-bias"
    //   ) as HTMLInputElement;
    //   const strictBoundsInputElement = document.getElementById(
    //     "use-strict-bounds"
    //   ) as HTMLInputElement;
    //   const options = {
    //     fields: ["formatted_address", "geometry", "name"],
    //     strictBounds: false,
    //     types: ["establishment"],
    //   };

    //   map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);

    //   const autocomplete = new google.maps.places.Autocomplete(input, options);

    //   // Bind the map's bounds (viewport) property to the autocomplete object,
    //   // so that the autocomplete requests use the current map bounds for the
    //   // bounds option in the request.
    //   autocomplete.bindTo("bounds", map);

    //   const infowindow = new google.maps.InfoWindow();
    //   const infowindowContent = document.getElementById(
    //     "infowindow-content"
    //   ) as HTMLElement;

    //   infowindow.setContent(infowindowContent);

    //   const marker = new google.maps.Marker({
    //     map,
    //     anchorPoint: new google.maps.Point(0, -29),
    //   });

    //   autocomplete.addListener("place_changed", () => {
    //     infowindow.close();
    //     marker.setVisible(false);

    //     const place = autocomplete.getPlace();

    //     if (!place.geometry || !place.geometry.location) {
    //       // User entered the name of a Place that was not suggested and
    //       // pressed the Enter key, or the Place Details request failed.
    //       window.alert("No details available for input: '" + place.name + "'");
    //       return;
    //     }

    //     // If the place has a geometry, then present it on a map.
    //     if (place.geometry.viewport) {
    //       map.fitBounds(place.geometry.viewport);
    //     } else {
    //       map.setCenter(place.geometry.location);
    //       map.setZoom(17);
    //     }

    //     marker.setPosition(place.geometry.location);
    //     marker.setVisible(true);

    //     infowindowContent.children["place-name"].textContent = place.name;
    //     infowindowContent.children["place-address"].textContent =
    //       place.formatted_address;
    //     infowindow.open(map, marker);
    //   });

    //   // Sets a listener on a radio button to change the filter type on Places
    //   // Autocomplete.
    //   function setupClickListener(id, types) {
    //     const radioButton = document.getElementById(id) as HTMLInputElement;

    //     radioButton.addEventListener("click", () => {
    //       autocomplete.setTypes(types);
    //       input.value = "";
    //     });
    //   }

    //   setupClickListener("changetype-all", []);
    //   setupClickListener("changetype-address", ["address"]);
    //   setupClickListener("changetype-establishment", ["establishment"]);
    //   setupClickListener("changetype-geocode", ["geocode"]);
    //   setupClickListener("changetype-cities", ["(cities)"]);
    //   setupClickListener("changetype-regions", ["(regions)"]);

    //   biasInputElement.addEventListener("change", () => {
    //     if (biasInputElement.checked) {
    //       autocomplete.bindTo("bounds", map);
    //     } else {
    //       // User wants to turn off location bias, so three things need to happen:
    //       // 1. Unbind from map
    //       // 2. Reset the bounds to whole world
    //       // 3. Uncheck the strict bounds checkbox UI (which also disables strict bounds)
    //       autocomplete.unbind("bounds");
    //       autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });
    //       strictBoundsInputElement.checked = biasInputElement.checked;
    //     }

    //     input.value = "";
    //   });

    //   strictBoundsInputElement.addEventListener("change", () => {
    //     autocomplete.setOptions({
    //       strictBounds: strictBoundsInputElement.checked,
    //     });

    //     if (strictBoundsInputElement.checked) {
    //       biasInputElement.checked = strictBoundsInputElement.checked;
    //       autocomplete.bindTo("bounds", map);
    //     }

    //     input.value = "";
    //   });
    // }

    // window.initMap = initMap;
    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.init();
      },
      null,
      this.title,
      null,
      "Add address"
    );
  }

  selectEmirates(e) {
    this.emiratesId = e.target.value;
    this.handleAreaList();
  }

  selectArea(e) {
    this.areaId = e.target.value;
  }

  handleLogin() {
    var token = this.ApiService.getLocalStorageItem("loginToken");
    if (!token) {
      this.ApiService.goTo("cart", this.router);
    }
  }

  handleEmiratesList() {
    this.ngLoader = true;
    this.ApiService.getEmiratesList().subscribe((data) => {
      this.ngLoader = false;
      this.emiratesData = data["items"];
      // console.log(this.emiratesData)
    });
  }

  handleAreaList() {
    this.ngLoader = true;
    this.ApiService.getAreaList(this.emiratesId).subscribe((data) => {
      this.ngLoader = false;
      this.areaData = data["items"];
      console.log("Items are ");
      console.log(JSON.stringify(this.areaData));
      console.log(this.areaData);
    });
  }

  // window.initMap = function() {}
}
