import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-option-shimmer',
  templateUrl: './payment-option-shimmer.component.html',
  styleUrls: ['./payment-option-shimmer.component.scss']
})
export class PaymentOptionShimmerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
