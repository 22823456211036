import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
//import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from 'src/app/services/api.service';
import {Title} from "@angular/platform-browser"; 
import { LogicalFileSystem } from '@angular/compiler-cli/src/ngtsc/file_system';
@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  imageBase: any;
  listData: any;
  backgroundImage:any;

  constructor(private router: Router, private route: ActivatedRoute, private ApiService:ApiService,private title: Title) { }
 
  
  ngOnInit() { 
    this.ApiService.setRestaurantSlug(this.route,fn => {
      console.log("Splash component called...");
      this.handleSplashScreen()  
    }, null, this.title);  
   // localStorage.setItem('restaurantName', 'Paragon');
    
  }

   // this.route.params.subscribe(params => {
    //   console.log(params) 
    // })

  handleSplashScreen(){
    //this.spinner.show();
    this.ApiService.getSplashScreen().subscribe((res)=> {
      console.log(res);
      
      this.imageBase = res['imageBase']
      this.listData = res['item']
      this.backgroundImage = this.imageBase + this.listData.backgroundImage;
      console.log(this.backgroundImage);
      
      if(this.listData != null){
         setTimeout(() => {
        //this.spinner.hide();
        this.ApiService.goTo('products', this.router);  
    }, 3000);  //5s 
      }
     
    })
  }

}
