import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import Swal from "sweetalert2";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit {
  userName: any;
  userDetails: any;
  oderTypeValue: any;
  deliverAddress: boolean = false;
  disabledProceed: boolean = false;
  noOrderType: boolean = false;
  addressData: any;
  selected: any;
  localOrderType: any;
  metaData: any;
  isDineIn: any;
  isHomeDelivery: any;
  isTakeAway: any;
  OrderType: any;
  loading: boolean;
  deliveryAddress: any = "";
  noAddress: boolean = false;
  hideFeature: boolean = false;
  constructor(
    public ApiService: ApiService,
    private toastr: ToastrService,
    public router: Router,
    private route: ActivatedRoute,
    private title: Title
  ) {}

  oderType(event: any) {
    this.oderTypeValue = event.target.defaultValue;
    this.noAddress = false;
    this.disabledProceed = true;
    if (this.oderTypeValue == "deliver_address") {
      this.deliverAddress = true;
    } else {
      this.deliverAddress = false;
    }
    this.ApiService.setLocalStorageItem("OrderType", this.oderTypeValue);
  }
  handleLogin() {
    var token = this.ApiService.getLocalStorageItem("loginToken");
    if (!token) {
      this.ApiService.goTo("cart", this.router);
    }
  }
  goBack() {
    this.ApiService.goTo("cart", this.router);
  }
  ClickAddress(index, id, ads_type, ads_emirates, ads_Area, ads_addressLine) {
    if (this.addressData && index < this.addressData.length) {
      for (var i in this.addressData) {
        this.addressData[i].is_selected = false;
      }

      var emirateId = this.addressData[index].emirates
        ? this.addressData[index].emirates.id
        : null;
      console.log("Emirate id is " + emirateId);
      this.addressData[index].is_selected = true;
    }
    this.deliveryAddress = id;
    const object = {
      id: id,
      addressType: ads_type,
      emirates: ads_emirates,
      area: ads_Area,
      line: ads_addressLine,
      emirateId: emirateId,
    };
    this.ApiService.setLocalStorageItem(
      "deliveryAddress",
      JSON.stringify(object)
    );
  }
  deleteAddress(id) {
    Swal.fire({
      title: "Delete Address",
      text: "Are you sure you want to delete this address ?. This cannot be undone",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.value) {
        this.ApiService.delAddress(id).subscribe((data: any) => {
          if (data.success == 1) {
            this.toastr.success(data.message);
            this.handleAddressList();
          } else {
            this.toastr.warning(data.message);
          }
        });
      }
    });
  }
  editAddress(id) {
    let urlPath = "edit-address/" + String(id);
    this.ApiService.goTo(urlPath, this.router);
  }
  ProceedOrderingMethod() {
    this.noAddress = false;
    var orderType = this.OrderType;
    if (orderType == "deliver_address") {
      var deliveryAddress =
        this.ApiService.getLocalStorageItem("deliveryAddress");
      if (!deliveryAddress) {
        this.noAddress = true;
        return;
      }
    } else {
      this.ApiService.removeLocalStorageItem("deliveryAddress");
    }
    if (!orderType) {
      this.noOrderType = true;
      return;
    }

    this.ApiService.goTo("confirm-order", this.router);
  }
  ngOnInit() {
    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.init();
      },
      null,
      this.title,
      null,
      "Choose ordering method"
    );
  }
  handleEmptyCart() {
    var cartItems = this.ApiService.getLocalStorageItem("cartItem");
    var isCartEmpty = true;
    if (cartItems) {
      cartItems = JSON.parse(cartItems);
      if (cartItems.length) {
        isCartEmpty = false;
      }
    }
    if (isCartEmpty) {
      this.ApiService.goTo("cart", this.router);
    }
  }
  showLoader() {
    this.loading = true;
  }
  hideLoader() {
    this.loading = false;
  }
  loadMeta(cb) {
    this.showLoader();

    this.ApiService.getMeta((meta: any) => {
      this.hideLoader();
      cb(meta);
    }, false);
  }
  init() {
    this.handleLogin();
    this.handleEmptyCart();
    this.handleAddressList();
    // this.localStorageChecking()
    this.userDetails = JSON.parse(
      this.ApiService.getLocalStorageItem("loginToken")
    );
    this.userName = this.userDetails.value.name;
    //this.metaData = JSON.parse(this.ApiService.getLocalStorageItem("metaData"));
    this.loadMeta((metaData) => {
      this.metaData = metaData;
      this.isDineIn = this.metaData.isDineInEnabled;
      this.isHomeDelivery = this.metaData.isHomeDeliveryEnabled;
      this.isTakeAway = this.metaData.isTakeAwayEnabled;
      var orderType = this.ApiService.getLocalStorageItem("OrderType");
      if (orderType) {
        this.OrderType = orderType;
        this.disabledProceed = true;
      }
    });

    // if(JSON.parse(this.ApiService.getLocalStorageItem('OrderType')) === "deliver_address"){
    //   this.OrderType = "deliver_address"
    //   this.disabledProceed = true
    // }
    // else if(JSON.parse(this.ApiService.getLocalStorageItem('OrderType')) === "get_from"){
    //   this.OrderType = "get_from"
    //   this.disabledProceed = true
    // }
    // else if(JSON.parse(this.ApiService.getLocalStorageItem('OrderType')) === "Dine_in"){
    //   this.OrderType = "Dine_in"
    //   this.disabledProceed = true
    // }
  }

  handleAddressList() {
    if (this.ApiService.getLocalStorageItem("deliveryAddress") != null) {
      this.deliveryAddress = JSON.parse(
        this.ApiService.getLocalStorageItem("deliveryAddress")
      );
    }
    this.ApiService.getAddressList().subscribe((data: any) => {
      var items = data.items;
      if (items.length == 0) {
        this.ApiService.removeLocalStorageItem("deliveryAddress");
        this.deliverAddress = null;
      } else {
        var deliveryAddress = this.deliveryAddress;
        if (deliveryAddress) {
          var item;
          for (var i in items) {
            item = items[i];
            if (item.id == deliveryAddress.id) item.is_selected = true;
          }
        } else {
          items.forEach((item) => {
            if (item.isDefault) {
              item.is_selected = true;
              return;
            }
          });
        }
      }

      this.addressData = items;
      // console.log(this.addressData);
    });
  }
}
