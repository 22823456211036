import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { NgForm } from "@angular/forms";
import {Title} from "@angular/platform-browser"; 
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild("ngConfirmPassword") ngConfirmPassword: any;
  PassCodeConfig = {
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: false,
    allowNumbersOnly: true,
    inputClass: "bg-light",
    placeholder: "",
    inputStyles: {
      width: "23%",
      height: "40px",
    },
  };

  @ViewChild("ngNewPassword") ngNewPassword: any;
  config = {
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: false,
    allowNumbersOnly: true,
    inputClass: "bg-light",
    placeholder: "",
    inputStyles: {
      width: "23%",
      height: "40px",
    },
  };
  newPassword = "";
  confirmPassword = "";
  resetToken: any;
  validForm: boolean = false;
  isMetaLoaded = false;
  logoImage = "assets/img/logo.png";

  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private title: Title
  ) {}
  loading: boolean;
  showLoader() {
    this.loading = true;
  }
  hideLoader() {
    this.loading = false;
  }
  loadMeta(cb) {
    this.showLoader();

    this.ApiService.getMeta((meta: any) => {
      this.hideLoader();
      cb(meta);
    });
  }
  onNewPassword(code) {
    this.newPassword = code;
    this.inputValidation();
  }

  onConfirmPassword(code) {
    this.confirmPassword = code;
    this.inputValidation();
  }

  inputValidation() {
    if (
      this.confirmPassword.length == 4 &&
      this.confirmPassword == this.newPassword
    ) {
      this.validForm = true;
    } else {
      this.validForm = false;
    }
  }

  init() {
    this.loadMeta((metaData) => {
      console.log("Meta data received is ");
      console.log(metaData);
      var resetToken = JSON.parse(this.ApiService.getLocalStorageItem("resetToken"));
      var userLogged = JSON.parse(this.ApiService.getLocalStorageItem("loginToken"));
      if (!resetToken || userLogged) {
        this.ApiService.goTo("forgot-password", this.router);
      }
      this.resetToken = resetToken;
      let  meta = metaData;
      if (meta && meta.imageBase && meta.logoImage) {
        var logoImage = `${meta.imageBase}${meta.logoImage}`;
        this.logoImage = logoImage;
      }
      this.isMetaLoaded = true;
    });
  }
  ngOnInit() {
    this.ApiService.setRestaurantSlug(this.route, (fn) => {
      this.init();
    }, null, this.title, null, "Reset password");
  }
  sendPasscode() {
    var body = {
      customerId: this.resetToken.customerId,
      token: this.resetToken.token,
      newPassword: this.newPassword,
    };

    console.log(body);
    this.ApiService.resetPassword(body).subscribe((res: any) => {
      if (res.success == 1) {
        this.toastr.success(res.message);
        this.ApiService.removeLocalStorageItem("resetToken");
        this.ApiService.goTo("login", this.router);
      }
      console.log(res);
    });
  }
}
