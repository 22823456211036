export const regEx = Object.freeze({
    nameRegEx: /^[a-z ,.'-]+$/i,
    restaurantNameRegEx: /^[a-z ,.'-]+$/i,
    categoryNameRegEx: /^[a-z ,.'-]+$/i,
    productNameRegEx: /^[a-z ,.'-]+$/i,
    addressRegEx: /^[a-z ,.'-]+$/i,
    slugRegEx: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    noSpaceAtStartRegEx: /^[^\s]+(\s+[^\s]+)*$/,
    emailRegEx: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    skuNumberRegEx: /^[0-9]*$/,
    priceRegEx: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
    descriptionTextRegEx: /^[\.a-zA-Z0-9,!? -]*$/,
    ingredientsTextRegEx: /^[\.a-zA-Z0-9,!? -]*$/,
});
