import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "src/app/services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {Title} from "@angular/platform-browser"; 

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm;
  loginRes;
  otp: string;
  userLogged: any;
  showOtpComponent = true;
  isMetaLoaded =  false;
  logoImage = "assets/img/logo.png";
  queryParams = {}
  @ViewChild("ngOtpInput") ngOtpInput: any;
  config = {
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    allowNumbersOnly: true,
    inputClass: "bg-light",
    placeholder: "",
    inputStyles: {
      width: "23%",
      height: "40px",
    },
  };
  loading: boolean;
  showLoader() {
    this.loading = true;
  }
  hideLoader() {
    this.loading = false;
  }
  loadMeta(cb) {
    this.showLoader();

    this.ApiService.getMeta((meta: any) => {
      this.hideLoader();
      cb(meta);
    }, false);
  }
  constructor(
    private fb: FormBuilder,
    public ApiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router,
    private title: Title
  ) {
    this.loginForm = this.fb.group({
      phone: ["", [Validators.required]],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  onSubmit() {
    let redirect = this.queryParams["redirectTo"];
    this.ApiService.loginSubmit(this.otp, this.loginForm.value.phone).subscribe(
      (data: any) => {
        if (data.success == 1) {
          let url = "order-details";
          let isFullUrl = false;
          if(redirect) {
            url = redirect;
            isFullUrl = true;
          }
          
          this.toastr.success(data.message);
          this.ApiService.goTo(url, this.router, isFullUrl);
        } else {
          this.toastr.warning(data.message);
        }
      },
      (error) => {}
    );
  }

  handleLogin() {
    var token = this.ApiService.getLocalStorageItem("loginToken");
    if (token) {
      this.ApiService.goTo("cart", this.router);
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  init() {
    this.loadMeta((metaData) => {
      console.log("Meta data received is ");
      console.log(metaData);
      this.userLogged = JSON.parse(this.ApiService.getLocalStorageItem("loginToken"));
      var meta = metaData;
      if (meta && meta.imageBase && meta.logoImage) {
        var logoImage = `${meta.imageBase}${meta.logoImage}`;
        this.logoImage = logoImage;
      }
      if (this.userLogged) {
        this.ApiService.goTo("cart", this.router);
      } 
      this.isMetaLoaded = true;
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.queryParams = params;
      this.ApiService.setRestaurantSlug(this.route, (fn) => {
        this.init();
      }, null, this.title, null, "Login");
    });
  }
}
