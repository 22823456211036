import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ToastrService } from "ngx-toastr";
import { JsonpClientBackend } from "@angular/common/http";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
})
export class ProductDetailsComponent implements OnInit {
  //Remove this
  sampleImagesTest = [
    "http://via.placeholder.com/600x350",
    "http://via.placeholder.com/610x350",
    "http://via.placeholder.com/620x350",
  ];
  isRestaurantOpen: boolean = true;
  relatedProductItemId: any;
  productItem: any;
  RestaurantId: string;
  foodId: string;
  foodCount: string;
  getlocalStorage: string;
  tag: any;
  preparationTimeMinutes: any;
  productName: any;
  productPrice: any;
  orderCounts: string;
  message: string;
  hideBtn = true;
  showBtn = false;
  imageBase;
  productImages = [];
  passingId;
  pageProductId;
  productRelatedItems;
  existingAddonValues = {};
  quantity: number = 0;
  i = 1;
  getStoredData: any;
  addOnsItem: any;
  openAddon: boolean;
  currentQty: any;

  modalQuantity: number = 1;
  count: number = 1;
  modalTotalAmount: number = 0;
  productTotalAmount: number = 0;
  isChecked;
  isCheckedName;
  addOns = [];
  pickedProducts = [];
  restaurantItemId: string;
  quantityTotal: number;
  productItems: any;
  productAddons: any;
  valueConut: any;
  ngLoader: boolean = false;
  maxQuantityPerOrder: any;
  minQuantityPerOrder: any;
  checkedAddonOptions: any = {};
  checkedOptions: any = {};

  constructor(
    public ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
    private title: Title
  ) {}
  ProductSlider: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      1024: {
        items: 1,
      },
    },
    nav: false,
  };
  relatedItemSlider: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      1024: {
        items: 3,
      },
    },
    nav: false,
  };

  plus() {
    console.log("Current quantity " + this.quantity);
    console.log("Maximum order quantity is " + this.maxQuantityPerOrder);
    if (this.quantity < this.maxQuantityPerOrder) {
      this.quantity++;
    } else {
      this.toastr.warning("Maximum order count is " + this.maxQuantityPerOrder);
    }
    this.localStoreOrderDetails(
      this.foodId,
      this.quantity,
      this.productPrice,
      this.RestaurantId
    );

    console.log("Updated quantity " + this.quantity);
    this.calculateTotalAmountAndSaveItemsToStorage();
    if (this.productAddons.length > 0) {
      this.openAddon = true;
    }
  }
  minus() {
    console.log("i is " + this.i);
    console.log("Min Q per order" + this.minQuantityPerOrder);
    var newQty = this.quantity - 1;
    if (newQty != this.minQuantityPerOrder) {
      //this.quantity--;
    } else {
      this.toastr.warning("Minimum order count is " + this.minQuantityPerOrder);
      //this.quantity = 0;
    }
    this.quantity = newQty;
    this.localStoreOrderDetails(
      this.foodId,
      this.quantity,
      this.productPrice,
      this.RestaurantId
    );

    this.calculateTotalAmountAndSaveItemsToStorage();
    if (this.quantity && this.productAddons.length > 0) {
      this.openAddon = true;
    }
  }
  addCount() {
    console.log("Addons array is " + JSON.stringify);
    this.hideBtn = false;
    this.showBtn = true;
    var quantity = this.quantity ? this.quantity : 0;
    if (quantity < this.minQuantityPerOrder) {
      quantity = this.minQuantityPerOrder;
    }
    this.quantity = quantity;
    this.modalQuantity = this.minQuantityPerOrder;
    this.i = this.minQuantityPerOrder;
    console.log(this.foodId);
    this.localStoreOrderDetails(
      this.foodId,
      this.quantity,
      this.productPrice,
      this.RestaurantId
    );
    this.modalTotalAmount = this.modalQuantity * parseInt(this.productPrice);
    if (this.productAddons.length > 0) {
      this.calculateTotalAmountAndSaveItemsToStorage();
      this.openAddon = true;
    }
  }
  closeAddon() {
    this.openAddon = false;
  }
  gotoDetails(id) {
    this.passingId = id;
    this.ApiService.goTo(`products/${this.passingId}`, this.router);
    // this.ApiService.getProductDetails(this.passingId).subscribe((data) => {
    //   this.imageBase = data['imageBase']
    //   this.productItem = data['item']
    //   this.productRelatedItems = data['relatedItems']
    //     this.ngOnInit()
    //     this.quantity = 0
    //     this.showBtn = false;
    //     this.hideBtn = true;

    // })
  }

  viewAllProductsInCategory(id) {
    this.relatedProductItemId = id;
    // this.ApiService.relatedProductItemFilter(this.relatedProductItemId);
    this.ApiService.goTo("related-products/" + id, this.router);
  }

  ngOnInit() {
    this.quantity = 0;
    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.handleProductDetails();
      },
      null,
      this.title,
      null,
      "Product details"
    );
    // this.localstorageCart()
  }
  //addons1
  formatPrice(value) {
    return Number(value).toFixed(2);
  }
  handleProductDetails() {
    this.pageProductId = this.route.snapshot.paramMap.get("slug");
    this.ngLoader = true;
    this.ApiService.getProductDetails(this.pageProductId).subscribe((data) => {
      console.log(data);
      this.ngLoader = false;
      this.isRestaurantOpen = data["isOpenNow"];
      this.imageBase = data["imageBase"];
      this.preparationTimeMinutes = data["preparationTimeMinutes"];
      this.productItem = data["item"];
      this.productRelatedItems = data["relatedItems"];
      //Formatting Prices of product to two decimals
      this.productItem.costPrice = Number(this.productItem.costPrice).toFixed(
        2
      );
      this.productItem.sellingPrice = Number(
        this.productItem.sellingPrice
      ).toFixed(2);
      this.productItem.discountedPrice = Number(
        this.productItem.discountedPrice
      ).toFixed(2);
      //Formatting Prices of goes well with products
      if (this.productRelatedItems.length) {
        this.productRelatedItems.forEach((item) => {
          if (item.costPrice)
            item.costPrice = Number(item.costPrice).toFixed(2);
          if (item.sellingPrice)
            item.sellingPrice = Number(item.sellingPrice).toFixed(2);
          if (item.discountedPrice)
            item.discountedPrice = Number(item.discountedPrice).toFixed(2);
        });
      }

      this.productPrice = this.productItem.discountedPrice;
      this.maxQuantityPerOrder = this.productItem.maxQuantityPerOrder;
      this.minQuantityPerOrder = this.productItem.minQuantityPerOrder;

      this.productItems = data["item"];

      //combine main image and subimages for displaying
      if (this.productItem.mainImage)
        this.productImages.push(this.productItem.mainImage);
      if (
        this.productItem.subImages &&
        Array.isArray(this.productItem.subImages)
      ) {
        this.productImages = this.productImages.concat(
          this.productItem.subImages
        );
      }
      this.modalTotalAmount = parseFloat(this.productItems.discountedPrice);
      this.productAddons = this.productItems["addOns"];

      //Setting Product addons to empty if status of all addons are zero
      let hasAddOn = false;
      this.productAddons.forEach((element) => {
        if (element.status) hasAddOn = true;
      });
      if (!hasAddOn) this.productAddons = [];

      console.log(
        "Product Related Items are " + JSON.stringify(this.productRelatedItems)
      );
      this.RestaurantId = data["restaurant"].id;
      this.addOnsItem = this.productItem.addOns;
      for (var addon of this.addOnsItem) {
        for (var op of addon.options) {
          this.checkedOptions[op._id] = false;
        }
      }
      this.foodId = this.productItem.itemId;

      this.tag = this.productItem.tag;
      this.preparationTimeMinutes = this.productItem.preparationTimeMinutes;
      this.productName = this.productItem.name;
      this.productPrice = this.productItem.discountedPrice;
      this.loadFromCartIfExisting();
    });
  }

  localStoreOrderDetails(foodID, foodCount, price, RestaurantID) {
    let total = price * foodCount;
    var oldItems =
      JSON.parse(this.ApiService.getLocalStorageItem("cartItem")) || [];
    var match = oldItems.find(function (item) {
      return item["restaurantItemId"] === foodID;
    });
    if (match) {
      match["quantity"] = foodCount;
      match["total"] = total;
    } else {
      var newItem = {
        restaurantItemId: foodID,
        quantity: foodCount,
        restaurantId: RestaurantID,
        productName: this.productName,
        tag: this.tag,
        preparationTimeMinutes: this.preparationTimeMinutes,
        price: this.productPrice,
        total: total,
        addOns: [],
      };
      oldItems.push(newItem);
    }
    this.ApiService.setLocalStorageItem("cartItem", JSON.stringify(oldItems));
  }

  //////////////////////// Addons //////////////////////////

  addonsSelectOne(
    index,
    addonId,
    addonName,
    optionId,
    optionName,
    qty,
    event,
    price
  ) {
    this.isChecked = event.target.checked;

    if (this.checkedOptions[optionId] == true) {
      if (this.isChecked) {
        this.isChecked = false;
      }
    }

    this.isCheckedName = optionName;
    this.checkedOptions[optionId] = this.isChecked;
    price = parseFloat(price);
    let addonObj = {};
    let optionObj = {};
    addonObj = {
      id: addonId,
      name: addonName,
      options: [],
    };
    optionObj = {
      id: optionId,
      name: optionName,
      quantity: qty,
      price: price,
    };
    addonObj["options"] = [];
    addonObj["options"].push(optionObj);
    let addonIndex = this.addOns.findIndex((x) => x.id === addonId);
    let indexOfAddon = this.productItems.addOns.findIndex(
      (x) => x._id === addonId
    );
    var addonOrig = this.productItems.addOns[indexOfAddon];
    for (var op of addonOrig.options) {
      if (op._id != optionId) this.checkedOptions[op._id] = false;
    }
    if (addonIndex > -1) {
      let isOld = false;
      if (indexOfAddon > -1) {
        let optionsArray = this.addOns[addonIndex].options;
        let oldOptionId = optionsArray[0].id;
        let oldOptionIndex = this.productItems.addOns[
          indexOfAddon
        ].options.findIndex((x) => x._id === oldOptionId);
        if (oldOptionIndex > -1) {
          let oldPrice =
            this.productItems.addOns[indexOfAddon].options[oldOptionIndex]
              .price;

          if (oldOptionId === optionId) {
            this.modalTotalAmount = this.modalTotalAmount - oldPrice;
            isOld = true;
          } else {
            this.modalTotalAmount = this.modalTotalAmount - oldPrice;
            this.modalTotalAmount = this.modalTotalAmount + price;
          }
        }
      }
      this.addOns.splice(addonIndex, 1);
      if (!isOld) {
        this.addOns.push(addonObj);
      }
    } else {
      this.modalTotalAmount = this.modalTotalAmount + price;
      this.addOns.push(addonObj);
    }

    this.calculateTotalAmountAndSaveItemsToStorage();
  }

  // changeQtyModal(changeVal, addedItem, sellingPrice) {
  //   this.modalQuantity = 1;
  //   if (changeVal === 'plus') {
  //     if (this.count != 100) {
  //       this.count++;
  //       this.modalQuantity = this.count;
  //       this.modalTotalAmount = this.modalTotalAmount + parseInt(sellingPrice)
  //     }
  //   }
  //   else {
  //     if (this.count != 1) {
  //       this.count--;
  //       this.modalQuantity = this.count;
  //       this.modalTotalAmount = this.modalTotalAmount - parseInt(sellingPrice)
  //     }
  //   }
  //   console.log(this.modalQuantity)
  // }
  loadCurrentQuantityInAddonForm() {
    var items =
      JSON.parse(this.ApiService.getLocalStorageItem("cartItem")) || null;
    var quantity = 1;
    if (items) {
      var item = items.find((item) => {
        return item["restaurantItemId"] === this.foodId;
      });

      quantity = item.quantity;
      console.log("Quantity found is " + quantity);
    }
  }

  count_plus(addedItem, sellingPrice) {
    //this is called inside addons popup
    if (this.i != this.maxQuantityPerOrder) {
      this.i++;
      this.quantity++;
      this.modalQuantity = this.quantity;
      this.modalTotalAmount = this.modalQuantity * parseInt(sellingPrice);
    } else {
      this.toastr.warning("Maximum order count is " + this.maxQuantityPerOrder);
    }
    this.calculateTotalAmountAndSaveItemsToStorage();
  }
  calculateTotalAmountAndSaveItemsToStorage() {
    var productPrice = this.productPrice ? this.productPrice : 0;
    var quantity = this.quantity ? this.quantity : 0;
    var totalPrice: any = productPrice * quantity;
    var addonPricing = {};
    var allAddons = this.productAddons;
    var options;
    var option;
    var optionId;
    var addonId;
    var price;
    for (var addon of allAddons) {
      options = addon.options;
      addonId = addon._id;
      //addonId = addon.restaurantItemOptionId;
      if (!addonPricing[addonId]) {
        addonPricing[addonId] = {};
      }
      for (option of options) {
        optionId = option._id;
        //optionId = option.restaurantItemOptionId;

        if (!addonPricing[addonId][optionId]) {
          addonPricing[addonId][optionId] = 0;
        }
        price = option.price;
        addonPricing[addonId][optionId] = price;
      }
    }
    var addons = this.addOns;
    var price;
    var optionQty: any = 0;
    for (var addon of addons) {
      addonId = addon.id;
      if (!addonPricing[addonId]) {
        continue;
      }
      for (var option of addon.options) {
        optionId = option.id;
        if (!addonPricing[addonId][optionId]) {
          continue;
        }
        price = addonPricing[addon.id][option.id];
        optionQty = option.quantity;
        optionQty = optionQty ? parseFloat(optionQty) : 0;
        price = price ? parseFloat(price) : 0;
        price = price * optionQty;
        totalPrice += price;
      }
    }
    totalPrice = totalPrice.toFixed(2);
    this.modalTotalAmount = totalPrice;
    this.saveItemsToLocalStorage();
  }
  loadFromCartIfExisting() {
    var cartItems =
      JSON.parse(this.ApiService.getLocalStorageItem("cartItem")) || [];
    var foodId = this.foodId;

    var item = null;
    var index: any = -1;
    var cartItem;
    for (var idx in cartItems) {
      cartItem = cartItems[idx];
      if (cartItem["restaurantItemId"] === foodId) {
        index = idx;
        break;
      }
    }
    if (index != -1) {
      item = cartItems[index];
      console.log("Addons are ");
      console.log(JSON.stringify(item.addOns));
      this.quantity = item.quantity;
      this.addOns = item.addOns;
      var existingAddOnValues = {};
      for (var addOn of item.addOns) {
        var addonId = addOn.id;
        if (!existingAddOnValues[addonId]) {
          existingAddOnValues[addonId] = {};
        }
        for (var option of addOn.options) {
          var optionId = option.id;
          var optionQty = option.quantity;
          if (!existingAddOnValues[addonId]) {
            existingAddOnValues[addonId][optionId] = 0;
          }

          existingAddOnValues[addonId][optionId] = optionQty;
        }
      }
      this.existingAddonValues = existingAddOnValues;
      for (var i in this.productAddons) {
        addOn = this.productAddons[i];
        var isMultiSelectEnabled = addOn.isMultiSelectEnabled;
        addonId = addOn._id;
        if (!existingAddOnValues[addonId]) {
          continue;
        }
        for (var j in addOn.options) {
          option = addOn.options[j];
          optionId = option._id;
          if ((optionQty = existingAddOnValues[addonId][optionId])) {
            this.checkedOptions[optionId] = true;
            if (isMultiSelectEnabled) {
              if (!this.checkedAddonOptions[addonId]) {
                this.checkedAddonOptions[addonId] = {};
              }
              this.checkedAddonOptions[addonId][optionId] = true;
            }
            this.productAddons[i].options[j].quantity = optionQty;
          }
        }
      }
      this.calculateTotalAmountAndSaveItemsToStorage();
    }
  }
  isAddonOptionCheckboxDisabled(addonId, optionId) {
    var ret = !this.checkOption(addonId, optionId); //selected option when page is loaded (from localstorage)
    if (!ret) {
      ret = !(
        this.checkedAddonOptions[addonId] &&
        this.checkedAddonOptions[addonId][optionId] != undefined
      );
    }

    return ret;
  }
  checkOption(addonId, optionId) {
    var ret = false;
    console.log("Existing addon values are ");
    console.log(this.existingAddonValues);
    console.log(
      "Checking option " +
        optionId +
        " of addon " +
        addonId +
        " need to be checked or not"
    );
    if (
      this.existingAddonValues[addonId] &&
      this.existingAddonValues[addonId][optionId]
    ) {
      ret = true;
    }
    console.log("Value of checked is ");
    console.log(ret);
    return ret;
  }
  saveItemsToLocalStorage() {
    var cartItems =
      JSON.parse(this.ApiService.getLocalStorageItem("cartItem")) || [];
    var foodId = this.foodId;
    var item = {
      restaurantItemId: foodId,
      quantity: this.quantity,
      restaurantId: this.RestaurantId,
      productName: this.productName,
      tag: this.tag,
      preparationTimeMinutes: this.preparationTimeMinutes,
      price: this.productPrice,
      total: this.modalTotalAmount,
      addOns: this.addOns,
    };
    var index: any = -1;
    var cartItem;
    for (var idx in cartItems) {
      cartItem = cartItems[idx];
      if (cartItem["restaurantItemId"] === foodId) {
        index = idx;
        break;
      }
    }
    if (index != -1) {
      cartItems[index] = item;
    } else {
      index = cartItems.length;
      cartItems.push(item);
    }
    if (this.quantity <= 0) {
      cartItems.splice(index, 1);
    }
    this.ApiService.setLocalStorageItem("cartItem", JSON.stringify(cartItems));
  }
  count_minus(addedItem, sellingPrice) {
    var quantity = this.quantity;
    quantity--;
    //this is called inside addons popup
    if (quantity >= this.minQuantityPerOrder) {
      this.i--;
      //this.modalTotalAmount =  this.modalQuantity * parseInt(sellingPrice)
    } else {
      quantity = 0;
      this.openAddon = false;
    }
    this.quantity = quantity;
    this.modalQuantity = this.quantity;
    this.calculateTotalAmountAndSaveItemsToStorage();
  }

  AddProductToCart(productId, name, price, qty, productTotalPrice) {
    var oldItems =
      JSON.parse(this.ApiService.getLocalStorageItem("cartItem")) || [];
    var match = oldItems.find(function (item) {
      return item["restaurantItemId"] === productId;
    });
    if (match) {
      match["quantity"] = qty;
      match["price"] = price;
      match["total"] = productTotalPrice;
      match["addOns"] = this.addOns;
    } else {
      var newItem = {};
      newItem["restaurantItemId"] = productId;
      newItem["productName"] = name;
      newItem["price"] = price;
      newItem["quantity"] = qty;
      newItem["total"] = productTotalPrice;
      newItem["veg"] = this.tag;
      newItem["preparationTimeMinutes"] = this.preparationTimeMinutes;
      newItem["addOns"] = this.addOns;
      oldItems.push(newItem);
    }
    this.ApiService.setLocalStorageItem("cartItem", JSON.stringify(oldItems));
    console.log(oldItems);
  }

  modifyQty(i, j, type, addonId, addonName, optionId, optionName, price) {
    console.log("Modify qty is called...");
    price = parseFloat(price);
    let indexOfOriginalAddon = this.productItems.addOns.findIndex(
      (x) => x._id === addonId
    );
    if (indexOfOriginalAddon < 0) {
      this.toastr.warning(`Some error occured.`);
      return;
    }
    var originalAddon = this.productItems.addOns[indexOfOriginalAddon];
    let maxOptionCount = originalAddon.maxAllowedOptionsCount;
    if (maxOptionCount == 0) {
      this.toastr.warning(`Options for this addon are not available.`);
      return;
    }

    let addonObj = {
      id: addonId,
      name: addonName,
      options: [],
    };
    let optionObj = {
      id: optionId,
      name: optionName,
      quantity: 1,
      price: price,
    };
    var addonIndex = this.addOns.findIndex((x) => x.id === addonId);
    if (addonIndex == -1) {
      addonObj["options"].push(optionObj);
      this.addOns.push(addonObj);
      this.productAddons[i].options[j].quantity = 1;
    } else {
      var mainAddon: any = {};
      mainAddon = this.addOns[addonIndex];
      var addonName = mainAddon.name;
      let isHigherMaximumQty = this.checkQuantity(
        mainAddon["options"],
        type,
        maxOptionCount
      );
      if (isHigherMaximumQty) {
        this.toastr.warning(
          `Maximum ${maxOptionCount} options are allowed for ${addonName}`
        );
        return;
      }
      var indexOfOption = mainAddon["options"].findIndex(
        (x) => x.id === optionId
      );
      if (indexOfOption == -1) {
        if (type === "plus") {
          this.addOns[addonIndex].options.push(optionObj);
          this.productAddons[i].options[j].quantity = 1;
        }
      } else {
        let qty = parseInt(mainAddon["options"][indexOfOption].quantity);
        if (!isHigherMaximumQty) {
          console.log("Within available qty range");
          if (type === "plus") {
            qty = qty + 1;
          } else {
            qty = qty - 1;
          }
          if (qty >= 0) {
            this.productAddons[i].options[j].quantity = qty;
            this.addOns[addonIndex].options[indexOfOption].quantity = qty;
            if (qty === 0) {
              if (this.addOns[addonIndex].options.length > 1) {
                this.addOns[addonIndex].options.splice(indexOfOption, 1);
              } else {
                this.addOns.splice(addonIndex, 1);
              }
            }
          }
        }
      }
    }
    this.calculateTotalAmountAndSaveItemsToStorage();
  }
  modifyQtyOld(i, j, type, addonId, addonName, optionId, optionName, price) {
    console.log("Modify qty is called...");
    price = parseFloat(price);

    let indexOfAddon = this.productItems.addOns.findIndex(
      (x) => x._id === addonId
    );
    let maxOptionCount =
      this.productItems.addOns[indexOfAddon].maxAllowedOptionsCount;

    var addonIndex = this.addOns.findIndex((x) => x.id === addonId);
    if (addonIndex > -1) {
      console.log("Addon found");
      var mainAddon: any = {};
      mainAddon = this.addOns[addonIndex];
      var addonName = mainAddon.name;
      var indexOfOption = mainAddon["options"].findIndex(
        (x) => x.id === optionId
      );
      if (indexOfOption > -1) {
        console.log("Option found");
        let qty = parseInt(mainAddon["options"][indexOfOption].quantity);
        console.log("Current qty is " + qty);
        let isHigherMaximumQty = this.checkQuantity(
          mainAddon["options"],
          type,
          maxOptionCount
        );
        if (!isHigherMaximumQty) {
          console.log("Within available qty range");
          if (type === "plus") {
            qty = qty + 1;
            this.modalTotalAmount = this.modalTotalAmount + price;
          } else {
            qty = qty - 1;
            if (qty >= 0) {
              this.modalTotalAmount = this.modalTotalAmount - price;
            }
            console.log("New qty is " + qty);
          }
          if (qty >= 0) {
            this.productAddons[i].options[j].quantity = qty;
            this.addOns[addonIndex].options[indexOfOption].quantity = qty;
            if (qty === 0) {
              if (this.addOns[addonIndex].options.length > 1) {
                this.addOns[addonIndex].options.splice(indexOfOption, 1);
              } else {
                this.addOns.splice(addonIndex, 1);
              }
            }
          }
        } else {
          console.log("Is higher than maximum allowed option count");

          this.toastr.warning(
            `Maximum ${maxOptionCount} options are allowed for ${addonName}`
          );
        }
      } else {
        console.log("Option not found");
        if (type === "plus") {
          let isHigherMaximumQty = this.checkQuantity(
            mainAddon["options"],
            type,
            maxOptionCount
          );
          if (!isHigherMaximumQty) {
            let optionObj = {
              id: optionId,
              name: optionName,
              quantity: 1,
            };
            this.addOns[addonIndex].options.push(optionObj);
            this.productAddons[i].options[j].quantity = 1;
            this.modalTotalAmount = this.modalTotalAmount + price;
          }
        }
      }
    } else {
      console.log("Addon not found");
      if (type === "plus") {
        let addonObj = {};
        let optionObj = {};
        addonObj = {
          id: addonId,
          name: addonName,
          options: [],
        };
        optionObj = {
          id: optionId,
          name: optionName,
          quantity: 1,
        };
        addonObj["options"] = [];
        addonObj["options"].push(optionObj);
        this.addOns.push(addonObj);
        this.productAddons[i].options[j].quantity = 1;
        this.modalTotalAmount = this.modalTotalAmount + price;
      }
    }
    this.valueConut = this.productAddons[i].options[j].quantity;
    this.calculateTotalAmountAndSaveItemsToStorage();
  }
  checkQuantity(options, type, maxOptionCount) {
    let qty = 0;
    for (let i = 0; i < options.length; i++) {
      qty = qty + parseInt(options[i].quantity);
    }
    if (type === "plus") {
      qty = qty + 1;
    } else {
      qty = qty - 1;
    }

    if (maxOptionCount < qty) {
      return true;
    } else {
      return false;
    }
  }
}
