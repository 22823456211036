import { Component, OnInit, ApplicationRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SwUpdate, SwPush } from "@angular/service-worker";
import { interval } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "./services/api.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  apiData: any;
  tokenDetails: any;
  expiryDate: any;
  slug: any;

  constructor(
    private http: HttpClient,
    private update: SwUpdate,
    private appRef: ApplicationRef,
    private router: Router,
    private route: ActivatedRoute,
    private ActivRoute: ActivatedRoute,
    public ApiService: ApiService,
    private title: Title
  ) {
    this.updateClient();
    this.checkUpdate();
  }

  // ngDoCheck() {
  //   this.handleRestaurantMetaData()
  // }
  onRouterOutletActivate(component) {
    component.route.params.subscribe((params) => {
      this.slug = params["restaurant-slug"];
    });
  }
  ngOnInit() {
    this.ApiService.setRestaurantSlug(this.route, (fn) => {
      console.log(
        `Restaurant slug set as`,
        this.ApiService.getRestaurantSlug()
      );
      // if(this.ApiService.getRestaurantSlug() == undefined)
      // {
      //     this.ApiService.noRestaurant = true;
      // }
      console.log("dagsdg");
    });
    this.handleloginTokenExpire();
    this.handleRestaurantMetaData();
    //console.log(this.ApiService.getLocalStorageItem('restaurantName'))

    // console.log(this.ActivRoute.snapshot.paramMap.get('slug') + 'exampl')

    //  this.ActivRoute.params.subscribe(params => {
    //   console.log(params)
    // })
    // for(let i=0;  i == 0; i++ ){
    //   console.log(i)

    //    this.ActivRoute.params.subscribe(params => {
    //      console.log(params.slug + 'test')
    //    })

    // }
  }

  handleloginTokenExpire() {
    if (this.ApiService.getLocalStorageItem("loginToken")) {
      this.tokenDetails = JSON.parse(
        this.ApiService.getLocalStorageItem("loginToken")
      );
      this.expiryDate = this.tokenDetails.exp;
      //console.log(this.tokenDetails)
      const CurrentDate = Math.floor(Date.now() / 1000);

      if (CurrentDate > this.expiryDate) {
        this.ApiService.removeLocalStorageItem("loginToken");
      }
      // console.log((CurrentDate - this.expiryDate))
    }
  }
  handleRestaurantMetaData() {
    console.log("Setting page title");
    // this.ApiService.getMeta((metaData) => {
    //   this.title.setTitle('page title changed');
    // });
  }

  updateClient() {
    if (!this.update.isEnabled) {
      console.log("Not Enabled");
      return;
    }
    this.update.available.subscribe((event) => {
      console.log(`current`, event.current, `available `, event.available);
      if (confirm("update available for the app please conform")) {
        this.update.activateUpdate().then(() => location.reload());
      }
    });

    this.update.activated.subscribe((event) => {
      console.log(`current`, event.previous, `available `, event.current);
    });
  }

  checkUpdate() {
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(1 * 60 * 60 * 1000);

        timeInterval.subscribe(() => {
          this.update.checkForUpdate().then(() => console.log("checked"));
          console.log("update checked");
        });
      }
    });
  }
}
