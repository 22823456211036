import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Title} from "@angular/platform-browser"; 

@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss']
})
export class ShareLinkComponent implements OnInit { 
  qrCode;
  constructor(private toastr: ToastrService, private ApiService: ApiService, private route: ActivatedRoute, private router: Router, private title: Title) { }
 

  copyPath() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.qrCode));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.toastr.success('Content copied to clipboard');
  }
  ngOnInit() {
    this.ApiService.setRestaurantSlug(this.route,fn => { 
      this.init()
    }, null, this.title, null, "Share this app");   
  }
  init() {
    this.qrCode =  window.location.href.substring(0, window.location.href.lastIndexOf('/'));
  }

}
