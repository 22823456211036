import { Component, OnInit, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/services/api.service";

@Component({
  selector: "app-cart-button",
  templateUrl: "./cart-button.component.html",
  styleUrls: ["./cart-button.component.scss"],
})
export class CartButtonComponent implements OnInit {
  constructor(
    public ApiService: ApiService,
    public toasterService: ToastrService
  ) {}
  @Input() product;
  @Input() cartItems;
  @Input() restaurantId;
  @Input() isOpenNow;
  item: any;
  quantity = 1;

  init() {
    let item = null;
    let cartItem;
    for (let key in this.cartItems) {
      cartItem = this.cartItems[key];
      if (cartItem["restaurantItemId"] === this.product.itemId) {
        this.item = cartItem;
        this.quantity = this.item.quantity;
        break;
      }
    }
  }
  getCartItems() {
    return JSON.parse(this.ApiService.getLocalStorageItem("cartItem")) || [];
  }

  addItemToCart() {
    const quantity = this.quantity > 0 ? this.quantity : 1;
    let item = {
      restaurantItemId: this.product.itemId,
      quantity,
      restaurantId: this.restaurantId,
      productName: this.product.name,
      tag: this.product.tag,
      preparationTimeMinutes: this.product.preparationTimeMinutes,
      price: this.product.discountedPrice,
      total: quantity * this.product.discountedPrice,
      addOns: [],
    };
    let index: any = -1;
    let cartItem;
    let cartItems = this.getCartItems();
    for (var idx in cartItems) {
      cartItem = cartItems[idx];
      if (cartItem["restaurantItemId"] === this.product.itemId) {
        index = idx;
        break;
      }
    }
    if (index != -1) {
      cartItems[index] = item;
    } else {
      index = cartItems.length;
      cartItems.push(item);
    }
    if (quantity <= 0) {
      cartItems.splice(index, 1);
    }
    this.ApiService.setLocalStorageItem("cartItem", JSON.stringify(cartItems));
  }

  showSuccess() {
    this.toasterService.success("Product Added Successfully");
  }
  ngOnInit(): void {
    this.init();
  }
  incementQuantity(increment) {
    console.log(this.product.minQuantityPerOrder);
    console.log(this.product.maxQuantityPerOrder);

    let newQuantity = this.quantity + increment;
    if (newQuantity < 0) return;
    // if(newQuantity < this.product.minQuantityPerOrder)
    if (newQuantity < 0) newQuantity = this.product.minQuantityPerOrder;
    if (newQuantity > this.product.maxQuantityPerOrder) {
      newQuantity = this.product.maxQuantityPerOrder;
    } else if (newQuantity === 0) {
      this.removeItemToCart();
      return;
    }
    this.quantity = newQuantity;
    this.addItemToCart();
    /* else if (newQuantity > this.product.maxQuantityPerOrder) */

    // this.addItemToCart();
  }
  itemExistOnCart = (returnType = undefined) => {
    const productList = this.getCartItems();

    if (returnType) {
      const cartProductExist = productList.filter(
        (product) => product.restaurantItemId !== this.product.itemId
      );
      return cartProductExist?.length ? cartProductExist : [];
    }
    const cartProductExist = productList.filter(
      (product) => product.restaurantItemId === this.product.itemId
    );
    return cartProductExist?.length ? true : false;
  };
  removeItemToCart = () => {
    const cartItems = this.itemExistOnCart("product");
    this.ApiService.setLocalStorageItem("cartItem", JSON.stringify(cartItems));
  };
  checkAddonNeeded = () => {
    this.ApiService.getProductDetails(this.product.slug).subscribe(
      (data: any) => {
        if (!data?.item?.addOns?.length) {
          this.addItemToCart();
          this.showSuccess();
        }
      },
      (error) => {}
    );
  };
}
