import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class InfoComponent implements OnInit {
  imageBase: any;
  infoPages = [];
  ngLoader = false;
  constructor(
    private ApiService: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public router: Router,
    private title: Title
  ) {}
  goToInfoPage(infoPage) {
    this.ApiService.infoPageDetails = infoPage;
    this.ApiService.goTo(`info/${infoPage.slug}`, this.router);
  }
  ngOnInit(): void {
    this.ngLoader = true;
    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.ngLoader = false;
        this.init();
      },
      null,
      this.title,
      null,
      "Know more about us"
    );
  }
  init() {
    this.ngLoader = true;
    this.ApiService.getInfoPages().subscribe((data: any) => {
      this.ngLoader = false;
      console.log("Info Pages api result is");
      console.log(data);

      this.infoPages = data.items;
    });
  }
}
