import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { User } from "../../services/user.model";
import { NgForm } from "@angular/forms";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import {Title} from "@angular/platform-browser"; 

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  phoneRegister: FormGroup = new FormGroup({});
  otp: string;
  @ViewChild("ngOtpInput") ngOtpInput: any;
  config = {
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: false,
    allowNumbersOnly: true,
    inputClass: "bg-light",
    placeholder: "",
    inputStyles: {
      width: "23%",
      height: "40px",
    },
  };
  logoImage = "assets/img/logo.png";
  phoneOTP: any;
  otpContainer: boolean = false;
  phoneNumber: any;
  loading: boolean;
  isMetaLoaded = false;
  showLoader() {
    this.loading = true;
  }
  hideLoader() {
    this.loading = false;
  }
  loadMeta(cb) {
    this.showLoader();

    this.ApiService.getMeta((meta: any) => {
      this.hideLoader();
      cb(meta);
    }, false);
  }
  constructor(
    private ApiService: ApiService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    this.phoneRegister = this.fb.group({
      phone: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      validatePhone: [0],
    });
  }
  get f() {
    return this.phoneRegister.controls;
  }
  onOtpChange(otp) {
    this.otp = otp;
  }
  setVal1(val) {
    this.ngOtpInput.setValue(val);
  }

  init() {
    this.loadMeta((metaData) => {
      console.log("Meta data received is ");
      console.log(metaData);
      var userLogged = JSON.parse(this.ApiService.getLocalStorageItem("loginToken"));

      var meta = metaData;
      if (meta && meta.imageBase && meta.logoImage) {
        var logoImage = `${meta.imageBase}${meta.logoImage}`;
        this.logoImage = logoImage;
      }
      this.isMetaLoaded = true;
      if (userLogged) {
        this.ApiService.goTo("cart", this.router);
      }
    });
  }

  ngOnInit() {
    this.ApiService.setRestaurantSlug(this.route, (fn) => {
      this.init();
    },null, this.title, null,"Forgot password");
  }

  back() {
    this.otpContainer = false;
  }

  onPhoneSubmit() {
    this.ApiService.forgotPassword(this.phoneRegister.value).subscribe(
      (res: any) => {
        console.log(res);
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.phoneOTP = res["otp"];
          this.phoneNumber = res["phone"];
          this.otpContainer = true;
          // this.toastr.success(this.phoneOTP);
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.toastr.error("Some Error Occured");
      }
    );
  }

  onOtpSubmit() {
    const body = {
      otp: this.otp,
      phone: this.phoneNumber,
    };

    this.ApiService.verifyOtp(body).subscribe(
      (res: any) => {
        console.log(res);
        if (res.success == 1) {
          this.ApiService.setLocalStorageItem("resetToken", JSON.stringify(res));
          this.toastr.success(res.message);
          this.ApiService.goTo("reset-password", this.router);
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {}
    );
  }

  resendOtp() {
    this.onPhoneSubmit();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
