import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./page/home/home.component";
import { ProductDetailsComponent } from "./page/product-details/product-details.component";
import { NotFoundComponent } from "./page/not-found/not-found.component";
import { AboutComponent } from "./page/about/about.component";
import { ShareLinkComponent } from "./page/share-link/share-link.component";
import { OrdersComponent } from "./page/orders/orders.component";
import { SplashComponent } from "./page/splash/splash.component";
import { AppComponent } from "./app.component";
import { RegisterComponent } from "./page/register/register.component";
import { LoginComponent } from "./page/login/login.component";
import { OrderDetailsComponent } from "./page/order-details/order-details.component";
import { AddAddressComponent } from "./page/add-address/add-address.component";
import { WifiComponent } from "./page/wifi/wifi.component";
import { ShopsComponent } from "./page/shops/shops.component";
import { PromoCodeComponent } from "./page/promo-code/promo-code.component";
import { AuthGuard } from "./auth/auth.guard";
import { PaymentOptionComponent } from "./page/payment-option/payment-option.component";
import { APP_BASE_HREF, Location } from "@angular/common";
import { TrackOrderComponent } from "./page/track-order/track-order.component";
import { OrderReportComponent } from "./page/order-report/order-report.component";
import { OffersComponent } from "./page/offers/offers.component";
import { OrderPlacedComponent } from "./page/order-placed/order-placed.component";
import { ForgotPasswordComponent } from "./page/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./page/reset-password/reset-password.component";
import { EditAddressComponent } from "./page/edit-address/edit-address.component";
import { InfoComponent } from "./page/info/info.component";
import { InfoPageDetailComponent } from "./page/info-page-detail/info-page-detail.component";

// live path
export function getBaseLocation() {
  // //var restaurantSlug = this.route.paramMap.get('restaurant-slug');
  // console.log("Slug is "+restaurantSlug);
  // let paths: string[] = location.pathname.split('/').splice(1, 2);
  // let basePath: string = (paths && paths[1]) || '';
  // let basePathLive: string = (paths && paths[0])+'/'+(paths && paths[1]);
  // localStorage.setItem('restaurantName', basePath);
  // return '/' + basePathLive;
}
// local path
//  export function getBaseLocation() {
//   let paths: string[] = location.pathname.split('/').splice(1, 1);
//   let basePath: string = (paths && paths[0]) || '';
//   localStorage.setItem('restaurantName', basePath);
//   return '/' + basePath;
// }

const routes: Routes = [
  {
    path: ":restaurant-slug",
    redirectTo: ":restaurant-slug/splash",
    pathMatch: "full",
  },
  { path: ":restaurant-slug/splash", component: SplashComponent },
  { path: ":restaurant-slug/products", component: HomeComponent },
  {
    path: ":restaurant-slug/related-products/:categoryId",
    component: HomeComponent,
  },
  {
    path: ":restaurant-slug/products/:slug",
    component: ProductDetailsComponent,
  },
  { path: ":restaurant-slug/about", component: AboutComponent },
  //{ path: ':restaurant-slug/wifi', component: WifiComponent  },
  { path: ":restaurant-slug/info", component: InfoComponent },
  { path: ":restaurant-slug/info/:slug", component: InfoPageDetailComponent },
  { path: ":restaurant-slug/shops", component: ShopsComponent },
  { path: ":restaurant-slug/scan-qr-code", component: ShareLinkComponent },
  { path: ":restaurant-slug/cart", component: OrdersComponent },
  { path: ":restaurant-slug/register", component: RegisterComponent },
  { path: ":restaurant-slug/login", component: LoginComponent },
  {
    path: ":restaurant-slug/forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: ":restaurant-slug/reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: ":restaurant-slug/order-details",
    component: OrderDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":restaurant-slug/add-address",
    component: AddAddressComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":restaurant-slug/edit-address/:id",
    component: EditAddressComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":restaurant-slug/confirm-order",
    component: PromoCodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":restaurant-slug/payment-options",
    component: PaymentOptionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":restaurant-slug/track-order/:id",
    component: TrackOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":restaurant-slug/track-order",
    component: TrackOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":restaurant-slug/invoices/:id",
    component: OrderReportComponent,
    canActivate: [AuthGuard],
  },
  { path: ":restaurant-slug/offers", component: OffersComponent },
  {
    path: ":restaurant-slug/order-summary/:id",
    component: OrderPlacedComponent,
    canActivate: [AuthGuard],
  },

  // { path:  ':slug', component: AppComponent,
  //   children: [
  //       { path: 'splash', component: SplashComponent  },
  //       { path: 'about', component: AboutComponent  },
  //       { path: 'share-link', component: ShareLinkComponent  },
  //       { path: 'order', component: OrdersComponent  },
  //       { path: 'register', component: RegisterComponent  },
  //       { path: 'login', component: LoginComponent  },

  //     ]
  //   },

  // { path: "**", redirectTo: "/notFound" },
  // { path: 'notFound', component:NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
