import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs'; 
import {Router} from '@angular/router';
import { ApiService } from './api.service';

@Injectable()
export class TokenInterceptorService  implements HttpInterceptor{
  userToken: any;
  userDetails: any;

  
  constructor(public auth: ApiService,private router:Router) { }
  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>>{
    this.userDetails = JSON.parse(this.auth.getLocalStorageItem("loginToken"))
   if(this.userDetails){
    this.userToken = this.userDetails.token
   }
    let tokenizedReq = req.clone({
      setHeaders: {
        Authorization: "Bearer "  + this.userToken
      }
    })
    return next.handle(tokenizedReq)
  }
}
