import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { inherits } from "util";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
  modalOptions: NgbModalOptions;
  closeResult: string;
  orderDetails: any;
  getOrderItems: any;
  removedArray: any;
  metaData: any;
  cartItem: any;
  Itemquantity: string;
  qty: number = 1;
  subTotal: number = 0;
  loading: boolean = false;
  priceTotal;
  orderId;
  discountPrice: number = 0;
  orderPreperationTime = 0;
  userId: any;
  restId: any;
  count: any = 0;
  @Input() ordersListOnly: boolean = false;
  @Output() buttonClicked = new EventEmitter<boolean>();
  constructor(
    private modalService: NgbModal,
    private ApiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private title: Title
  ) {
    this.modalOptions = {
      backdrop: "static",
      backdropClass: "customBackdrop",
      centered: true,
      size: "md",
    };
  }

  open(content, i) {
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        // console.log('close 1')
        // this.getOrderItems = JSON.parse(this.ApiService.getLocalStorageItem('cartItem'));
        // this.removedArray = this.getOrderItems.splice(i, 1);
        // this.cartItem = this.getOrderItems;
        // this.ApiService.setLocalStorageItem('cartItem', JSON.stringify(this.cartItem));
        // this.ngOnInit();
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        // console.log('close 2')
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  cartContinue(subTotal, priceTotal) {
    console.log("Cart continue function called");
    const obj = { subtotal: subTotal, total: priceTotal };
    var meta: any = this.metaData;

    var minOrderAcceptanceAmount = meta.minOrderAcceptanceAmount
      ? meta.minOrderAcceptanceAmount
      : 0;
    if (subTotal < minOrderAcceptanceAmount) {
      this.toastr.warning(
        "Sorry. Orders below " +
          minOrderAcceptanceAmount +
          " AED will not be accepted."
      );
      return;
    }
    this.ApiService.setLocalStorageItem("cartAmount", JSON.stringify(obj));
    this.ApiService.setLocalStorageItem("orderNotes", "");
    this.ApiService.setLocalStorageItem(
      "preparationTimeMinutes",
      String(this.orderPreperationTime)
    );

    var token = this.ApiService.getLocalStorageItem("loginToken");
    var isLoggedIn = false;
    if (token) {
      token = JSON.parse(token);
      if (token) {
        isLoggedIn = true;
      }
    }
    var url = "login";
    if (isLoggedIn) {
      url = "order-details";
    }
    if (this.ordersListOnly) {
      this.buttonClicked.emit(true);
    } else {
      this.ApiService.goTo(url, this.router);
    }
  }
  init() {
    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.loadMeta((metaData) => {
          console.log("Meta data received is ");
          console.log(metaData);
          this.metaData = metaData;
          this.orderDetails = [];
          this.getLocalStorageOrders();
        });
      },
      null,
      this.title,
      null,
      "Cart"
    );
    this.getOrderIdIfExists();
  }
  getOrderIdIfExists() {
    if (this.ApiService.getLocalStorageItem("orderId") != null) {
      this.orderId = this.ApiService.getLocalStorageItem("orderId");
    } else {
      this.orderId = null;
    }
  }
  // FOR SINGLE ORDER TRACKING
  goToTrackOrder() {
    this.ApiService.goTo(`/track-order/${this.orderId}`, this.router);
  }
  // FIR MULTIPLE ORDER TRACKING ROUTE
  goToTrackOrder1() {
    this.ApiService.goTo(`/track-order`, this.router, false, { active: false });
  }
  showLoader() {
    this.loading = true;
  }
  hideLoader() {
    this.loading = false;
  }
  loadMeta(cb) {
    this.showLoader();

    this.ApiService.getMeta((meta: any) => {
      this.hideLoader();
      cb(meta);
    });
  }
  ngOnInit() {
    this.init();
    var user_id: any = JSON.parse(localStorage.getItem("loginToken"));
    if (user_id) {
      this.userId = user_id.value.id;
      console.log(this.userId);
      this.restId = localStorage.getItem("restaurantId");
      console.log(this.restId);
      this.ApiService.getAllActiveOrdersCount(
        this.userId,
        this.restId
      ).subscribe((res: any) => {
        console.log(res);
        this.count = res.count;
      });
    }
    // if (!this.orderDetails) {
    //   this.router.navigate(['/product-list']);
    // }
    //console.log(this.subTotal)
  }
  ngDoCheck() {
    this.loadTotalAmounts();
  }
  formatPrice(value) {
    return Number(value).toFixed(2);
  }
  loadTotalAmounts() {
    var items = this.getLocalStorageOrders();
    var totalAmount: any = 0;
    for (var item of items) {
      var itemTotal = item.total;
      itemTotal = itemTotal ? parseFloat(itemTotal) : 0;
      totalAmount += itemTotal;
    }

    totalAmount = totalAmount.toFixed(2);
    this.subTotal = totalAmount;
    this.priceTotal = totalAmount;
  }
  getLocalStorageOrders() {
    this.orderDetails = [];
    var orderDetails = this.ApiService.getLocalStorageItem("cartItem");
    if (orderDetails) {
      orderDetails = JSON.parse(orderDetails);
      if (orderDetails) {
        if (Array.isArray(orderDetails) && orderDetails.length) {
          orderDetails.forEach((detailsItem) => {
            if (
              detailsItem.preparationTimeMinutes &&
              this.orderPreperationTime <
                Number(detailsItem.preparationTimeMinutes)
            ) {
              this.orderPreperationTime = Number(
                detailsItem.preparationTimeMinutes
              );
            }
          });
        }
        this.orderDetails = orderDetails;
      }
    }
    return this.orderDetails;
  }

  decrementQty(orderData: any, index: number) {
    if (orderData["quantity"] - 1 < 1) {
      this.getOrderItems = JSON.parse(
        this.ApiService.getLocalStorageItem("cartItem")
      );
      this.removedArray = this.getOrderItems.splice(index, 1);
      this.cartItem = this.getOrderItems;
      this.ApiService.setLocalStorageItem(
        "cartItem",
        JSON.stringify(this.cartItem)
      );
    } else {
      orderData["quantity"] -= 1;
      this.localStoreOrderDetails(
        orderData["restaurantItemId"],
        orderData["quantity"],
        orderData["price"],
        this.orderDetails[index]["total"],
        this.orderDetails[index]["restaurantId"]
      );
    }
    if (this.ordersListOnly) {
      this.cartContinue(this.subTotal, this.priceTotal);
    }
  }
  incrementQty(orderData: any) {
    console.log(orderData["restaurantItemId"]);
    if (orderData["quantity"] + 1 < 1) {
      orderData["quantity"] = 1;
      this.localStoreOrderDetails(
        orderData["restaurantItemId"],
        orderData["quantity"],
        orderData["price"],
        orderData["total"],
        orderData["restaurantId"]
      );
    } else {
      orderData["quantity"] += 1;
      this.localStoreOrderDetails(
        orderData["restaurantItemId"],
        orderData["quantity"],
        orderData["price"],
        orderData["total"],
        orderData["restaurantId"]
      );
    }
    if (this.ordersListOnly) {
      this.cartContinue(this.subTotal, this.priceTotal);
    }
  }

  localStoreOrderDetails(Id, quantity, price, total, restaurantId) {
    total = price * quantity;
    var oldItems =
      JSON.parse(this.ApiService.getLocalStorageItem("cartItem")) || [];
    var match = oldItems.find(function (item) {
      return item["restaurantItemId"] === Id;
    });
    for (var i in oldItems) {
      var item = oldItems[i];
      var qty = item.quantity;
      var price = item.price;
      var totalAmount = parseFloat(price) * parseInt(quantity);
      var addons = item.addOns;
      for (var addon of addons) {
        var options = addon.options;
        for (var option of options) {
          var price = option.price;
          var qty = option.quantity;
          var optionPrice = parseFloat(price) * parseInt(qty);
          totalAmount += optionPrice;
        }
      }
      //oldItems[i].total = totalAmount;
    }
    if (match) {
      match["quantity"] = quantity;
      match["total"] = totalAmount ? totalAmount : total;
    } else {
      var newItem = {
        quantity: quantity,
        total: total,
        restaurantId: restaurantId,
      };
      oldItems.push(newItem);
    }
    this.ApiService.setLocalStorageItem("cartItem", JSON.stringify(oldItems));
  }
}
