import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule} from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgOtpInputModule } from  'ng-otp-input';
import { ReactiveFormsModule} from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { AuthGuard } from './auth/auth.guard';

import { ApiService } from './services/api.service';
import { HomeComponent } from './page/home/home.component';
import { FooterComponent } from './page/footer/footer.component';
import { ProductDetailsComponent } from './page/product-details/product-details.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { AboutComponent } from './page/about/about.component';
import { ShareLinkComponent } from './page/share-link/share-link.component';
import { OrdersComponent } from './page/orders/orders.component';
import { SplashComponent } from './page/splash/splash.component';
import { LoginComponent } from './page/login/login.component';
import { RegisterComponent } from './page/register/register.component';
import { OrderDetailsComponent } from './page/order-details/order-details.component';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AddAddressComponent } from './page/add-address/add-address.component';
import { WifiComponent } from './page/wifi/wifi.component';
import { ShopsComponent } from './page/shops/shops.component';
import { PromoCodeComponent } from './page/promo-code/promo-code.component';
import { PaymentOptionComponent } from './page/payment-option/payment-option.component';
import { TrackOrderComponent } from './page/track-order/track-order.component';
import { OrderReportComponent } from './page/order-report/order-report.component';
import { OffersComponent } from './page/offers/offers.component';
import { OrderPlacedComponent } from './page/order-placed/order-placed.component';
import { ForgotPasswordComponent } from './page/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './page/reset-password/reset-password.component';
import { PaymentOptionShimmerComponent } from './payment-option-shimmer/payment-option-shimmer.component';
import { NgxShimmerLoadingModule } from  'ngx-shimmer-loading';
import { PromoCodeShimmerComponent } from './promo-code-shimmer/promo-code-shimmer.component';
import { QRCodeModule } from 'angularx-qrcode'; 
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgNumericKeyboardModule } from 'ng-numeric-keyboard';
import { EditAddressComponent } from './page/edit-address/edit-address.component';
import { InfoComponent } from './page/info/info.component';
import { InfoPageDetailComponent } from './page/info-page-detail/info-page-detail.component';
import { TimingDisplayComponent } from './page/timing-display/timing-display.component';
import { CartButtonComponent } from './page/cart-button/cart-button.component'; 
//import { APP_BASE_HREF, Location } from '@angular/common';

 
// export function getBaseLocation() {
//   let paths: string[] = location.pathname.split('/').splice(1, 1);
//   let basePath: string = (paths && paths[0]) || '';
//   localStorage.setItem('restaurantName', JSON.stringify(paths));
//   return '/' + basePath;
// }

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    ProductDetailsComponent,
    NotFoundComponent,
    AboutComponent,
    ShareLinkComponent,
    OrdersComponent,
    SplashComponent,
    LoginComponent,
    RegisterComponent,
    OrderDetailsComponent,
    AddAddressComponent,
    WifiComponent,
    ShopsComponent,
    PromoCodeComponent,
    PaymentOptionComponent,
    TrackOrderComponent,
    OrderReportComponent,
    OffersComponent,
    OrderPlacedComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PaymentOptionShimmerComponent,
    PromoCodeShimmerComponent,
    EditAddressComponent,
    InfoComponent,
    InfoPageDetailComponent,
    TimingDisplayComponent,
    CartButtonComponent, 
    //CarouselHolderComponent 
    
  ],
  imports: [
    NgxShimmerLoadingModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbModule, 
    CarouselModule, 
    NgOtpInputModule,
    ReactiveFormsModule,
    QRCodeModule, 
    ShareButtonsModule, 
    ShareIconsModule, 
    NgNumericKeyboardModule,
    AgmCoreModule.forRoot({ //Google map key
      apiKey: 'AIzaSyBJX--Ia8e1A04g5KftXgboQiIG2x9E838'
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ApiService,AuthGuard,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: TokenInterceptorService, multi: true 
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
