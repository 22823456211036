import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { BrowserModule, DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-info-page-detail",
  templateUrl: "./info-page-detail.component.html",
  styleUrls: ["./info-page-detail.component.scss"],
})
export class InfoPageDetailComponent implements OnInit {
  ngLoader: boolean = false;
  pageTitle: string = "";
  pageContent: any = "";
  constructor(
    public ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    let slug = this.route.snapshot.paramMap.get("slug");
    this.ngLoader = true;
    this.pageTitle = this.ApiService.infoPageDetails.title;
    this.pageContent = this.sanitizer.bypassSecurityTrustHtml(
      this.ApiService.infoPageDetails.htmlContent
    );
    this.ngLoader = false;

    /* this.ApiService.getInfoPageDetail(slug).subscribe((data) => {
      console.log("---------------- Receive INfo page detail from api is ")
      console.log(data["item"])
      this.ngLoader = false;
      this.pageTitle = data["item"].title;
      this.pageContent =this.sanitizer.bypassSecurityTrustHtml(data["item"].htmlContent) ;
      
    }); */
  }
}
