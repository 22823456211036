import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as jwt_decode from "jwt-decode";
import { FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";

// import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
// import Base64 from 'crypto-js/enc-base64';

@Component({
  selector: "app-payment-option",
  templateUrl: "./payment-option.component.html",
  styleUrls: ["./payment-option.component.scss"],
})
export class PaymentOptionComponent implements OnInit {
  cardToken: any;
  cardDetails = [];
  openPanel: boolean;
  newCardForm;
  selected: any;
  payOfflineMethod: any;
  loading = true;
  // userToken: any;
  userDetails: any;
  userName: any;
  isOnlinePaymentEnabled = false;
  isCashOnDeliveryEnabled = false;
  isCardOnDeliveryEnabled = false;
  constructor(
    private fb: FormBuilder,
    private ApiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private title: Title
  ) {
    //  this.newCardForm = this.fb.group({
    //   nameOnCard: ['', [Validators.required]],
    //   cardNumber: ['', [Validators.required]],
    //   expiryMonth: ['', [Validators.required]],
    //   expiryYear: ['',[Validators.required] ],
    // })
  }

  //  get f() {
  //   return this.newCardForm.controls
  // }

  // onSubmit() {

  //   this.userToken =  JSON.parse(this.ApiService.getLocalStorageItem('UserToken'))

  //   this.ApiService.addCard(this.newCardForm.value)
  //     .subscribe((res: any) => {
  //       console.log(res)
  //       if (res.success == 1) {
  //        // this.handleListCard()
  //         this.toastr.success(res.message);
  //       } else {
  //         this.toastr.warning(res.message);
  //       }
  //     }, error => {

  //     })
  // }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  paymentMethod(event: any) {
    this.payOfflineMethod = event.target.defaultValue;
    console.log(this.payOfflineMethod);
    this.ApiService.setLocalStorageItem(
      "paymentMethod",
      JSON.stringify(this.payOfflineMethod)
    );
    this.ApiService.goTo("confirm-order", this.router);
  }

  // clickCard(_index,cardNumber, nameOnCard){
  //   this.selected = _index;
  //  const maskCardNumber= cardNumber.substring( 0, 2) + "XXXXXXXXXXXXX" + cardNumber.substring( 14, 16)
  //   const object = { cardNumber:maskCardNumber ,cardName:nameOnCard,}
  //   this.ApiService.setLocalStorageItem("paymentMethod", JSON.stringify(object));
  // }

  // deleteCard(id){
  //    this.toastr.warning('Not avilable');

  // }
  // openClosePanel(){
  //   this.openPanel = !this.openPanel
  // }

  proceedPaymentMethod() {
    //this.ApiService.setLocalStorageItem("paymentMethod", JSON.stringify(this.oderTypeValue));
    this.ApiService.goTo("confirm-order", this.router);
  }
  showLoader() {
    this.loading = true;
  }
  hideLoader() {
    this.loading = false;
  }
  handleLogin() {
    var token = this.ApiService.getLocalStorageItem("loginToken");
    if (!token) {
      this.ApiService.goTo("cart", this.router);
    }
  }
  initialize() {
    this.showLoader();
    this.ApiService.getRestaurantMetaData().subscribe((data: any) => {
      console.log("Data is ");
      console.log(JSON.stringify(data));
      if (data.success) {
        var item = data.item;
        for (var prop in item) {
          if (this[prop] !== undefined) {
            this[prop] = item[prop];
          }
        }
      }
      this.hideLoader();
    });
  }
  init() {
    // this.handleListCard()
    this.handleLogin();
    this.handleEmptyCart();
    this.userDetails = JSON.parse(
      this.ApiService.getLocalStorageItem("loginToken")
    );
    this.userName = this.userDetails.value.name;
    this.initialize();
    // const token =  {'cardDetails':"458646418964168"}

    // console.log(jwt_decode(token))
  }

  handleEmptyCart() {
    var cartItems = this.ApiService.getLocalStorageItem("cartItem");
    var isCartEmpty = true;
    if (cartItems) {
      cartItems = JSON.parse(cartItems);
      if (cartItems.length) {
        isCartEmpty = false;
      }
    }
    if (isCartEmpty) {
      this.ApiService.goTo("cart", this.router);
    }
  }
  ngOnInit() {
    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.init();
      },
      null,
      this.title,
      null,
      "Choose payment method"
    );
  }
  // handleListCard(){
  //   this.ApiService.getCardList().subscribe((res)=>{
  //   this.cardToken = res['items']
  //   this.cardToken.forEach((data) => {
  //     this.cardDetails.push(jwt_decode(data.cardDetails))
  // });

  //   })
  // }
}
