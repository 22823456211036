import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Renderer2,
  Inject,
  Input,
} from "@angular/core";
import { ApiService } from "../../services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, Observable, timer } from "rxjs";
import { Location, DOCUMENT } from "@angular/common";
import { map } from "rxjs/operators";
import { isArray } from "util";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() slug;
  private subscription: Subscription = new Subscription();
  interval: any;
  refreshDuration = 3000;
  activeOrderId = null;
  showActiveOrder: boolean = false;
  isActiveOrderLive: boolean = false;
  isActiveOrderCancelled: boolean = false;
  isActiveOrderCompleted: boolean = false;
  activeOrderLastStatus = null;
  isTrackingApiToBeCalled = true;
  orderCounts: number;
  cartEmptyMsg = false;
  orderDetails: any;
  totalFoodCount: number;
  orderId: string;
  metaData: any;
  activeMenu: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ApiService: ApiService,
    public location: Location,
    private toastr: ToastrService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = "reload"; // for refreshing when menu is accessed from menu
  }

  setDynamicScript() {
    console.log("Set dynamic script called");
    this.ApiService.getDynamicScript((dynamicScript) => {
      console.log("Dynamic script is", dynamicScript);
      if (dynamicScript) this.injectJs(dynamicScript);
    });
  }
  goTo(url) {
    this.ApiService.goTo(url, this.router);
    this.activeMenu = url;
  }
  ngOnChanges() {
    this.doNecessaryActions();
  }
  injectJs(js) {
    let script = this._renderer2.createElement("script");
    script.text = js;
    console.log(this._document);
    console.log(script);
    this._renderer2.appendChild(this._document.body, script);
    setTimeout(() => {
      let div: any = document.querySelector(`[id^="gb"]`);
      // div.style.bottom = "65px";
      // console.log(div);
      div.classList.add("gb-style");
    }, 2000);
  }
  goToTrackOrder() {
    this.ApiService.goTo(`/track-order/${this.orderId}`, this.router);
  }
  emptyOrderItem() {
    this.cartEmptyMsg = true;
  }
  closeAlert() {
    this.cartEmptyMsg = false;
  }
  liveTrackActiveOrder() {
    this.interval = setInterval(() => {
      if (
        this.ApiService.getLocalStorageItem("orderId") != null &&
        this.ApiService.getLocalStorageItem("loginToken") != null
      ) {
        console.log("Local storage order id is");
        console.log(this.ApiService.getLocalStorageItem("orderId"));
        let activeOrderId = this.ApiService.getLocalStorageItem("orderId");
        if (this.activeOrderId && activeOrderId != this.activeOrderId) {
          // this.toastr.error("Your active order is changed.");
          this.toastr.success("Your order is placed successfully.");
          this.isTrackingApiToBeCalled = true;
          this.showActiveOrder = true;
          this.ApiService.showActiveOrder = true;
          this.isActiveOrderCompleted = false;
          this.isActiveOrderCancelled = false;
          this.isActiveOrderLive = true;
          this.ApiService.isActiveOrderLive = true;
          this.activeOrderLastStatus = null;
        }
        this.activeOrderId = activeOrderId;
        if (this.isTrackingApiToBeCalled) {
          // this.ApiService.orderTrack(this.orderId).subscribe((data) => {
          //       if (data["isCancelled"]) {
          //         this.isTrackingApiToBeCalled = false;
          //         console.log("Cancelled order found");
          //         if(!this.isActiveOrderCancelled)
          //           this.toastr.error("Your order is cancelled by the restaurant.");
          //         this.showActiveOrder = true;
          //         this.ApiService.showActiveOrder = true;
          //         this.isActiveOrderLive = false;
          //         this.ApiService.isActiveOrderLive = false;
          //         this.isActiveOrderCancelled = true;
          //         this.isActiveOrderCompleted = false;
          //       } else {
          //         let orderStatuses = data["statusHistory"];
          //         if ( orderStatuses && isArray(orderStatuses) && orderStatuses.length ) {
          //           let isDelivered =  orderStatuses[orderStatuses.length - 1].isCompleted == true;
          //           if (isDelivered ) {
          //             this.isTrackingApiToBeCalled = false;
          //             console.log("Delivered order found")
          //             this.showActiveOrder = false;
          //             this.ApiService.showActiveOrder = false;
          //             this.isActiveOrderCompleted = true;
          //             this.isActiveOrderCancelled = false;
          //             this.isActiveOrderLive = false;
          //             this.ApiService.isActiveOrderLive = false;
          //             this.ApiService.removeLocalStorageItem("orderId");
          //             this.toastr.success("Your Order is delivered");
          //           } else {
          //             let latestStatus = null;
          //             for(let orderStatus of orderStatuses) {
          //               latestStatus = orderStatus.isCompleted?orderStatus.title: latestStatus;
          //             }
          //             if(latestStatus != this.activeOrderLastStatus) {
          //               if( this.activeOrderLastStatus) //only if not the first status
          //                 this.toastr.success(`Status of your active order is changed to '${latestStatus}'`);
          //               this.activeOrderLastStatus = latestStatus;
          //             }
          //             console.log("Live order found")
          //             this.showActiveOrder = true;
          //             this.ApiService.showActiveOrder = true;
          //             this.isActiveOrderCompleted = false;
          //             this.isActiveOrderCancelled = false;
          //             this.isActiveOrderLive = true;
          //             this.ApiService.isActiveOrderLive = true;
          //           }
          //         }
          //       }
          //   },
          //   (error) => {
          //     console.log("Error: Could not get active order status");
          //   }
          // )
        }
      } else {
        this.orderId = null;
        this.isTrackingApiToBeCalled = true;
        this.showActiveOrder = false;
        this.ApiService.showActiveOrder = false;
        this.isActiveOrderCompleted = false;
        this.isActiveOrderCancelled = false;
        this.isActiveOrderLive = false;
        this.ApiService.isActiveOrderLive = false;
        this.activeOrderId = null;
        this.activeOrderLastStatus = null;
      }
    }, this.refreshDuration);

    // if (
    //   this.ApiService.getLocalStorageItem("orderId") != null &&
    //   this.ApiService.getLocalStorageItem("loginToken") != null
    // ) {
    //   console.log("Order id exists");
    //   this.orderId = this.ApiService.getLocalStorageItem("orderId");
    //   this.interval = setInterval(() => {
    //     this.subscription.add(

    //     );
    //   }, this.refreshDuration);
    // }
  }
  ngOnInit() {
    let url = this.location.path();
    var menu = "products";
    var lastPart = url.split("/").pop();
    var possibleMenus = ["about", "wifi", "offers"];

    if (possibleMenus.indexOf(lastPart) != -1) {
      menu = lastPart;
    }
    this.activeMenu = menu;
    this.doNecessaryActions();
  }
  doNecessaryActions() {
    if (this.slug) {
      console.log("Slug is now set..");
      this.ApiService.setRestaurantSlug(
        null,
        () => {
          console.log("Starting live tracking");
          this.setDynamicScript();
          this.liveTrackActiveOrder();

          this.ApiService.getSplashScreen().subscribe((res) => {
            console.log("SPLASH SCREENNN RES!!!!!!!!!!!!");
            console.log(res);
            let imageBase = res["imageBase"];
            let listData = res["item"];
            let backgroundImage = imageBase + listData.backgroundImage;
            console.log(backgroundImage);
            var x: any = document.getElementsByTagName("BODY")[0];
            console.log(x);
            console.log(`url(${backgroundImage})`);

            x.style.backgroundImage = `url('${backgroundImage}')`;
          });
        },
        this.slug
      );
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.interval);
  }
  ngDoCheck() {
    // console.log("Checking for order updates...");
    this.getLocalStorageOrders();
    this.handleCartCount();
    if (this.orderDetails) {
      for (let i = 0; i < this.orderDetails.length; i++) {
        console.log(`Meta data id is`, this.metaData?.id);
        console.log(
          `Order details id is`,
          this.orderDetails[i]["restaurantId"]
        );
        if (
          this.metaData &&
          this.metaData.id != this.orderDetails[i]["restaurantId"]
        ) {
          this.ApiService.removeLocalStorageItem("cartItem");
        } else {
          this.handleCartCount();
        }
      }
    }

    if (this.ApiService.getLocalStorageItem("orderId") != null) {
      this.orderId = this.ApiService.getLocalStorageItem("orderId");
    } else {
      this.orderId = null;
    }
  }

  getLocalStorageOrders() {
    this.orderDetails = JSON.parse(
      this.ApiService.getLocalStorageItem("cartItem")
    );
    this.metaData = JSON.parse(this.ApiService.getLocalStorageItem("metaData"));
  }

  handleCartCount() {
    if (this.orderDetails) {
      this.totalFoodCount = 0;
      for (let i = 0; i < this.orderDetails.length; i++) {
        this.totalFoodCount =
          this.totalFoodCount + this.orderDetails[i]["quantity"];
      }
    } else {
      this.totalFoodCount = 0;
    }
  }
}
