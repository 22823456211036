import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import {Title} from "@angular/platform-browser"; 
import { isThisTypeNode } from "typescript";

@Component({
  selector: "app-track-order",
  templateUrl: "./track-order.component.html",
  styleUrls: ["./track-order.component.scss"],
})
export class TrackOrderComponent implements OnInit {
  orderId: any;
  statusHistory: any;
  isOnlinePayment: any;
  ngLoader: boolean = false;
  orderItem: any;
  orderIdNumber: any;
  isCancelled: boolean = false;
  cancellationReason = "Order Cancelled By The Restaurant";
  queryParams = {};
  isPageLoaded: boolean = false;
  timerRef = null;
  userId:any;
  restId:any;
  activeOrderList:any;
  constructor(
    public ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private title: Title
  ) {}

  ngOnInit() {
    var user_id :any= JSON.parse(localStorage.getItem("loginToken"));
    this.userId = user_id.value.id;
    console.log(this.userId);
    this.restId = localStorage.getItem("restaurantId");
    console.log(this.restId);

    this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.queryParams = params;
      this.ApiService.setRestaurantSlug(this.route, (fn) => {
        this.init();
      }, null, this.title, null, "Track order");

    });

    this.ApiService.getAllActiveOrders(this.userId,this.restId,this.queryParams).subscribe((res: any) => {
      console.log(res);
      this.activeOrderList = res;
      for(let i = 0;i<this.activeOrderList.length;i++)
      {
        let obj :any ={
          isCompleted:this.activeOrderList[i].isAccepted,
          orderStatusId:{
            name:'Accepted',
            title:'Order Accepted'
          }
        }
        if(this.activeOrderList[i].isCancelled)
        {
          // delete obj.isCompleted;
          obj.isCompleted = this.activeOrderList[i].isCancelled;
          obj.isCancelled = this.activeOrderList[i].isCancelled;
          obj.orderStatusId.name = "Cancelled";
          obj.orderStatusId.title = "Order Cancelled";
          obj.cancellationReason = this.activeOrderList[i].cancellationReason;
          // obj.orderStatusId. = "Order Cancelled";
        }
        this.activeOrderList[i].statusHistory.push(obj);
        let arr =this.activeOrderList[i].statusHistory;
        [arr[0],arr[1],arr[2],arr[3]]=  [arr[0],arr[3],arr[1],arr[2]]
        if(this.activeOrderList[i].isCancelled)
        {
          arr.splice(2,2);
        }
        this.activeOrderList[i].statusHistory = arr;
      
        // this.activeOrderList[i].statusHistory[4].isCompleted = this.activeOrderList[i].isAccepted;
        // this.activeOrderList[i].statusHistory[4].orderStatusId.name = 'Accepted';
        // this.activeOrderList[i].statusHistory[4].orderStatusId.title = 'Order Accepted';
        // this.activeOrderList[i].statusHistory[4] = { isCompleted:'' };
       
      }  
      this.ngLoader = false;
    })
  }
  formatPrice(value) {
    return Number(value).toFixed(2);
  }
  goToInvoice() {
    this.ApiService.goTo(`invoices/${this.orderId}`, this.router);
  }
  
  goToInvoice1(id) {
    this.ApiService.goTo(`invoices/${id}`, this.router);
  }
  updateOrderStatus() {

    // this.ApiService.orderTrack(this.orderId).subscribe((res: any) => {
    //   this.ngLoader = false;
    //   this.statusHistory = res["statusHistory"];
    //   this.orderItem = res["items"];
    //   console.log("Order item is")
    //   console.log(this.orderItem)
    //   this.orderIdNumber = this.orderId;
    //   this.isCancelled = res["isCancelled"];
    //   this.cancellationReason = res["cancellationReason"];
    //   console.log(this.statusHistory);
    //   if (this.statusHistory && this.statusHistory.length) {
    //     if (
    //       this.statusHistory[this.statusHistory.length - 1].isCompleted == true
    //     ) {
    //       console.log("Order is completed");
    //       if(this.timerRef) {
    //         clearInterval(this.timerRef);
    //         this.timerRef = null;
    //       }
    //       console.log("Removing local storage order id");
    //       this.ApiService.removeLocalStorageItem("orderId");
    //       console.log("Local storage order id after removing is");
    //       console.log(this.ApiService.getLocalStorageItem("orderId"));
    //     } else {
    //       console.log("Order is not completed");
    //     }
    //   }
    //   this.isPageLoaded = true;
    // });
  }
  init() {
    this.orderId = this.route.snapshot.paramMap.get("id");
    console.log('Order id is set as', this.orderId);
    this.ngLoader = true;
    this.timerRef = setInterval(() => { 
      console.log("Updating order status...");
      this.updateOrderStatus();
    },2000);


  }
}
