import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {} from "google-maps";
@Component({
  selector: "app-edit-address",
  templateUrl: "./edit-address.component.html",
  styleUrls: ["./edit-address.component.scss"],
})
export class EditAddressComponent implements OnInit {
  emiratesData: Object;
  emiratesId;
  areaData: any;
  areaId: any;
  addressFrom;
  addressId;
  latitude = 25.42;
  longitude = 55.47;
  // latitude ;
  // longitude ;
  locationChosen = false;
  addresstypes: any = false;
  isDefault: boolean = false;
  ngLoader = false;
  addressDetail: any;
  autoAddress: any = "";
  testVar = "Test Emirate";
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild("addresstext") addresstext: any;
  onChoseLocation(event) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.locationChosen = true;
  }
  constructor(
    private fb: FormBuilder,
    private ApiService: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.addressFrom = this.fb.group({
      emirates: ["", [Validators.required]],
      area: ["", [Validators.required]],
      addressLine: ["", [Validators.required]],
      addressType: ["Home", []],
      autoAddress: ["", [Validators.required]],
      isDefault: [""],
    });
  }
  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
  }
  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        // componentRestrictions: { country: 'US' },
        componentRestrictions: { country: "AE" },
        types: [this.adressType], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      this.autoAddress = place.name;
      console.log(place.name);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();
      this.locationChosen = true;
      console.log(this.latitude);
      console.log(this.longitude);

      this.invokeEvent(place);
    });
  }
  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
  get f() {
    return this.addressFrom.controls;
  }

  addressType(event: any) {
    console.log(event.target.defaultValue);
    this.addresstypes = event.target.defaultValue;
  }

  onSubmit() {
    const address_body = {
      emiratesId: this.addressFrom.value.emirates,
      deliveryAreaId: this.addressFrom.value.area,
      addressLine: this.addressFrom.value.addressLine,
      latitide: this.latitude,
      longitude: this.longitude,
      autoAddress: this.autoAddress,
      addressType: this.addressFrom.value.addressType,
      isDefault: this.addressFrom.value.isDefault,
    };
    this.ngLoader = true;
    this.ApiService.editAddress(this.addressId, address_body).subscribe(
      (res: any) => {
        this.ngLoader = false;
        if (res.success == 1) {
          this.ApiService.goTo("order-details", this.router);
          this.toastr.success(res.message);
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        let errorMessage = error.message ? error.message : "Uncaught Error";
        this.toastr.error(errorMessage);
        this.ngLoader = false;
      }
    );
  }

  handleEmptyCart() {
    var cartItems = this.ApiService.getLocalStorageItem("cartItem");
    var isCartEmpty = true;
    if (cartItems) {
      cartItems = JSON.parse(cartItems);
      if (cartItems.length) {
        isCartEmpty = false;
      }
    }
    if (isCartEmpty) {
      this.ApiService.goTo("cart", this.router);
    }
  }
  init() {
    this.handleLogin();
    this.handleEmptyCart();
  }
  ngOnInit() {
    let addressId = this.route.snapshot.paramMap.get("id");
    this.ngLoader = true;
    this.ApiService.getAddressDetail(addressId).subscribe((res: any) => {
      //this.ngLoader = false

      this.ApiService.getEmiratesList().subscribe((data: any) => {
        this.emiratesData = data.items;

        //finding current emirate object here
        let addressDetail = res["item"];
        this.addressId = addressDetail.id;
        this.emiratesId = addressDetail.emirates;
        this.areaId = addressDetail.deliveryArea;
        this.latitude = addressDetail.lat;
        this.longitude = addressDetail.lng;
        console.log(this.latitude);
        console.log(this.longitude);

        this.addresstypes = addressDetail.addressType;
        this.isDefault = addressDetail.isDefault;
        this.addressFrom.patchValue({
          emirates: this.emiratesId,
          area: this.areaId,
          addressLine: addressDetail.addressLine,
          addressType: addressDetail.addressType,
          isDefault: addressDetail.isDefault,
          autoAddress: addressDetail.autoAddress,
        });
        this.autoAddress = addressDetail.autoAddress;

        this.handleAreaList();
        this.init();
        console.log("Address Detail is " + JSON.stringify(addressDetail));
      });
    });
  }

  selectEmirates(e) {
    console.log(e);
    if (e.target.value !== "Select Emirate") {
      this.emiratesId = e.target.value;
      this.handleAreaList();
    }
  }

  selectArea(e) {
    if (e.target.value !== "Select Area") {
      this.areaId = e.target.value;
    }
  }

  handleLogin() {
    var token = this.ApiService.getLocalStorageItem("loginToken");
    if (!token) {
      this.ApiService.goTo("cart", this.router);
    }
  }

  handleEmiratesList() {
    this.ngLoader = true;
    this.ApiService.getEmiratesList().subscribe((data) => {
      this.ngLoader = false;
      this.emiratesData = data["items"];
      // console.log(this.emiratesData)
    });
  }

  handleAreaList() {
    this.ngLoader = true;
    this.ApiService.getAreaList(this.emiratesId).subscribe((data: any) => {
      const areaIdExistOnEmirateArea = data?.items?.find(
        (areaList) => areaList.id === this.areaId
      );
      this.ngLoader = false;

      this.areaData = data["items"];
      if (this.areaId && areaIdExistOnEmirateArea) {
        this.addressFrom.patchValue({
          area: this.areaId,
        });
      } else {
        this.addressFrom.patchValue({
          area: "",
        });
      }
    });
  }
}
