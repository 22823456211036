import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-offers",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.scss"],
})
export class OffersComponent implements OnInit {
  offerList: any;
  ngLoader: boolean = false;
  noData: boolean = false;
  constructor(
    private ApiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private title: Title
  ) {}

  init() {
    this.ngLoader = true;
    console.log("Loading offers...");
    this.ApiService.offerCodeApi().subscribe((res: any) => {
      this.ngLoader = false;
      console.log(res);
      if (res.success == 1) {
        let items = [];
        const records = res.items;
        for (let record of records) {
          const item = this.ApiService.addDatesFromUnixTs(record);
          items.push(item);
        }
        this.offerList = items;
        console.log("Offer list is ");
        console.log(this.offerList);
        if (this.offerList.length == 0) {
          this.noData = true;
        }
      }
    });
  }
  ngOnInit() {
    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.init();
      },
      null,
      this.title,
      null,
      "Offers"
    );
  }

  copyofferCode(code) {
    const offerCode = code ? code.toUpperCase() : "";
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", offerCode);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
    this.toastr.success("Offer Code copied to clipboard");
  }
}
