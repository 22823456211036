import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from "@angular/platform-browser";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  productItem;
  imageBase;
  isChecked;
  isOpenNow;
  openPanel: boolean;
  allCategoryCheck = true;
  isLoggedIn: Boolean = false;
  categoriesList: any;
  vegOnly = "";
  isSimpleLayoutEnabled = true;
  categoryId = "";
  passingData;
  passingId;
  keyword = "";
  ticker;
  productSlug;
  noImage: boolean = false;
  ngLoader: boolean = false;
  relatedProductItemId: any;
  viewType: any;
  isVegOnlyFilterEnabled: boolean;
  metaData;
  restaurantOriginalName;
  productImageBase;
  categoryImageBase;
  catNames = {};
  restaurantId = null;
  cartItems = null;
  categories: any;
  orderId: any;
  noRestaurant: any = false;
  userId: any;
  restId: any;
  count: any = 0;
  constructor(
    public ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private title: Title
  ) {}

  toggleDisplay(list, id) {
    list.show = !list.show;
    //this.categoryId = id
    console.log(id);
  }
  onSearchChange(searchValue: string): void {
    if (searchValue != "") {
      console.log(searchValue);
      this.keyword = searchValue;
      this.handleProductList();
    } else if (searchValue === "") {
      this.keyword = "";
      this.handleProductList();
    }
  }
  categoryMenu() {
    this.openPanel = !this.openPanel;
  }

  checkVegOnly(event: any) {
    this.vegOnly = event;
    this.handleProductList();
  }

  categoryMenuLink(id, _index) {
    console.log(id);
    this.categoryId = id;
    this.handleProductList();
    this.openPanel = !this.openPanel;
    this.allCategoryCheck = false;
    this.ticker = _index;
  }

  allCategoryItem() {
    this.allCategoryCheck = true;
    this.categoryId = null;
    this.handleProductList();
    this.openPanel = !this.openPanel;
    this.ticker = !this.ticker;
  }

  ngOnInit() {
    var user_id: any = JSON.parse(localStorage.getItem("loginToken"));
    if (user_id) {
      this.userId = user_id.value.id;
      console.log(this.userId);
      this.restId = localStorage.getItem("restaurantId");
      console.log(this.restId);
      this.ApiService.getAllActiveOrdersCount(
        this.userId,
        this.restId
      ).subscribe((res: any) => {
        console.log(res);
        this.count = res.count;
      });
    }

    // this.spinner.show();
    if (this.ApiService.getLocalStorageItem("orderId") != null) {
      this.orderId = this.ApiService.getLocalStorageItem("orderId");
    } else {
      this.orderId = null;
    }

    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.init();
      },
      null,
      this.title
    );
  }

  goToTrackOrder() {
    this.ApiService.goTo(`/track-order`, this.router, false, { active: true });
  }

  formatPrice(value) {
    return Number(value).toFixed(2);
  }
  init() {
    this.isLoggedIn = this.ApiService.loggedIn();
    this.cartItems = this.getCartItems();
    this.restaurantOriginalName = "Eato";
    var catId = this.route.snapshot.paramMap.get("categoryId");
    if (catId) {
      console.log("Category id is " + catId);
      this.categoryId = catId;
    } else {
      console.log("No category id is passed");
    }
    this.handleProductList();
    this.handleCategoriesList();
    this.ApiService.relatedItem.subscribe((result) => {
      if (result != "") {
        this.categoryId = result;
        this.handleProductList();
      }
    });
    this.ApiService.getMeta((metaData) => {
      this.metaData = metaData;
      this.restaurantOriginalName = this.metaData.name;
      console.log("Original name is " + this.metaData.name);
      // this.isVegOnlyFilterEnabled = this.ApiService.isVegOnlyFilterEnabled;
      // this.isVegOnlyFilterEnabled = false;
      console.log(metaData);
      // console.log(this.isVegOnlyFilterEnabled);
    });
  }
  ngDoCheck() {
    this.ApiService.relatedProductItemFilter("");
  }
  logout() {
    this.ApiService.logout();
    this.isLoggedIn = false;
  }
  handleProductList() {
    this.ngLoader = true;
    var productSlug = this.ApiService.getRestaurantSlug();
    console.log(productSlug);

    this.ApiService.getProductList(
      this.categoryId,
      productSlug,
      this.vegOnly,
      this.keyword
    ).subscribe(
      (data: any) => {
        console.log(data);

        this.ngLoader = false;
        this.restaurantId = data["restaurantId"];
        this.isOpenNow = data["isOpenNow"] || false;
        this.isSimpleLayoutEnabled = data["isSimpleLayoutEnabled"] || false;
        this.productImageBase = data["imageBase"];
        this.productItem = data["items"];
        this.categories = data.categories;
        this.productItem = this.productItem.filter((data) => {
          for (let i = 0; i < this.categories.length; i++) {
            if (
              this.categories[i].name == data.categoryName &&
              this.categories[i].isEnabled == true &&
              data.isEnabled
            )
              return data;
          }
        });
        console.log("PRODUCT ITEM !!!!!!!!!!!!!!!!!!");

        console.log(this.productItem);

        this.viewType = !this.isSimpleLayoutEnabled ? data["viewType"] : "list";

        if (this.productItem.length == 0) {
          this.noImage = true;
        } else {
          this.noImage = false;
        }
        setTimeout(() => {
          //this.spinner.hide();
        }, 500);
        // console.log(data)
      },
      (err) => {
        this.ngLoader = false;
        console.log(err);
        if (err.error.success == 0) {
          // this.noRestaurant = true;
          this.ApiService.noRestaurant = true;
        }
      }
    );
  }
  getCartItems() {
    return JSON.parse(this.ApiService.getLocalStorageItem("cartItem")) || [];
  }

  handleCategoriesList() {
    this.ApiService.getCategoriesList().subscribe((data) => {
      this.categoryImageBase = data["imageBase"];
      this.categoriesList = data["items"];
    });
  }
  gotoDetails(id) {
    if (this.isSimpleLayoutEnabled) return;
    this.passingId = id;
    var slug = id;
    this.ApiService.goTo(`products/${slug}`, this.router);
  }

  // goTo(url) {
  //   this.ApiService.goTo(url, this.router);
  // }
}
