import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-timing-display',
  templateUrl: './timing-display.component.html',
  styleUrls: ['./timing-display.component.scss']
})
export class TimingDisplayComponent implements OnInit {
  @Input() timing;
  constructor() { }

  ngOnInit(): void {
  }

}
