import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { User } from "../../services/user.model";
import { NgForm } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { customRegexValidator } from "../../helper/validator";
import { regEx } from "../../helper/constants";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  phoneRegister: FormGroup = new FormGroup({});
  phoneNumberInputForm: FormGroup = new FormGroup({});
  otp: string;
  passcode;
  phoneOTP;
  countryCode: any = "+971";
  isMetaLoaded = false;
  phoneNumber;
  phoneApiToken;
  user: User;
  showOtpComponent = true;
  steperContainer = false;
  noOtpVerification = false;
  registerData;
  //for otp verification enable and disable.
  OtpVerificationEnabled: boolean = false;
  registerStep = true;
  emailErr: any = "";
  phoneErr: any = "";
  loading: boolean = false;
  isChecked;
  phoneRespoMessage;

  logoImage = "assets/img/logo.png";

  phoneInputData: any;

  @ViewChild("ngPassCodeInput") ngPassCodeInput: any;
  PassCodeConfig = {
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    allowNumbersOnly: true,
    inputClass: "bg-light",
    placeholder: "",
    inputStyles: {
      width: "23%",
      height: "40px",
    },
  };

  @ViewChild("ngOtpInput") ngOtpInput: any;
  config = {
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    allowNumbersOnly: true,
    inputClass: "bg-light",
    placeholder: "",
    inputStyles: {
      width: "23%",
      height: "40px",
    },
  };
  disabledAgreement: boolean;

  constructor(
    private ApiService: ApiService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.phoneNumberInputForm = this.fb.group({
      countryCode: ["+971", [Validators.required]],
      phone: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      validatePhone: [0],
    });
    this.phoneRegister = this.fb.group({
      countryCode: ["+971", [Validators.required]],
      phone: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      name: [
        "",
        [
          Validators.required,
          customRegexValidator({
            pattern: regEx.nameRegEx,
            msg: "Invalid Name",
          }),
          customRegexValidator({
            pattern: regEx.noSpaceAtStartRegEx,
            msg: "Name shouldnt start with a space at start",
          }),
        ],
      ],
      validatePhone: [0],
    });
  }
  get f() {
    return this.phoneRegister.controls;
  }

  checkEmailExists(e) {
    var email = e.target.value;
    this.ApiService.checkUserExists(email, null).subscribe((data: any) => {
      console.log(data);
      if (data.success == 1) {
        if (data.exists) {
          this.emailErr = "Email already exists";
        } else {
          this.emailErr = "";
        }
      } else {
        this.toastr.error(data.message);
      }
    });
  }
  onOtpChange(otp) {
    this.otp = otp;
  }

  onPassCodeChange(code) {
    this.passcode = code;
  }

  setVal(val) {
    this.ngPassCodeInput.setValue(val);
  }

  setVal1(val) {
    this.ngOtpInput.setValue(val);
  }
  handleLogin() {
    var token = this.ApiService.getLocalStorageItem("loginToken");
    if (token) {
      this.ApiService.goTo("cart", this.router);
    }
  }
  showLoader() {
    this.loading = true;
  }
  hideLoader() {
    this.loading = false;
  }
  loadMeta(cb) {
    this.showLoader();

    this.ApiService.getMeta((meta: any) => {
      this.hideLoader();
      cb(meta);
    }, false);
  }
  init() {
    this.handleLogin();
    this.resetForm();
    console.log("Loading meta");
    this.loadMeta((metaData) => {
      console.log("Init called");
      //this.OtpVerificationEnabled = true;
      console.log("Meta data is", metaData);
      this.OtpVerificationEnabled = metaData.isOTPVerificationEnabled;
      console.log(`OTP verification status is`, this.OtpVerificationEnabled);
      let meta = metaData;
      if (!meta) return;
      if (meta && meta.imageBase && meta.logoImage) {
        var logoImage = `${meta.imageBase}${meta.logoImage}`;
        this.logoImage = logoImage;
      }
      console.log("meta is", meta);

      this.isMetaLoaded = true;

      let isOtpVerificationEnabled = meta.isOTPVerificationEnabled;
      console.log(
        "------------------------------ isotp enabled is " +
          isOtpVerificationEnabled
      );
      console.log(isOtpVerificationEnabled);
      if (isOtpVerificationEnabled && isOtpVerificationEnabled == true) {
        console.log("inside true block ------------------");
        this.OtpVerificationEnabled = true;
        this.registerStep = false;
      }
    });
    // this.checkSignedInUser()
  }
  ngOnInit() {
    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.init();
      },
      null,
      this.title,
      null,
      "Create account"
    );
  }

  resetForm(form?: NgForm) {
    if (form != null) form.reset();
    this.user = {
      name: "",
      email: "",
      password: "",
      countryCode: "+971",
      phone: "",
      restaurantSlug: "",
    };
  }

  acceptCondition(event: any) {
    if (event === "accept") {
      this.disabledAgreement = true;
    } else {
      this.disabledAgreement = false;
    }
  }

  OnSubmit(form: NgForm) {
    var slug = this.ApiService.getRestaurantSlug();
    if (this.OtpVerificationEnabled == true) {
      console.log("-------------- OTP verfication is enabled");
      console.log("phone value is " + this.phoneNumber);
      this.registerData = {
        password: this.passcode,
        name: form.value.name,
        email: form.value.email,
        countryCode: this.countryCode,
        phone: this.phoneNumber,
        restaurantSlug: slug,
      };
    } else {
      console.log("-------------- OTP verfication is not");
      console.log(this.countryCode);
      this.registerData = {
        password: this.passcode,
        name: form.value.name,
        email: form.value.email,
        countryCode: this.countryCode,
        phone: form.value.phone,
        restaurantSlug: slug,
      };
    }
    if (this.passcode.length != 4) {
      this.toastr.error("Password is not fully filled.");
      return;
    }
    console.log("About to call API for register ");
    console.log("Register user Data is ");
    console.log(this.registerData);
    this.ApiService.registerUser(this.registerData).subscribe((data: any) => {
      console.log("registerUser called");

      console.log(this.registerData);
      if (data.success == 1) {
        console.log("Registration is successfull ---------------------");
        this.resetForm(form);
        this.toastr.success(data.message);

        this.ApiService.goTo("order-details", this.router);
      } else {
        console.log("Registration is failed ---------------------");
        this.toastr.error(data.message);
      }
    });
  }

  resendOtp() {
    console.log(this.phoneNumber);
    let val = {
      countryCode: "+971",
      phone: this.phoneNumber,
      validatePhone: 0,
    };
    this.ApiService.formPhoneSubmit(val).subscribe((res: any) => {
      console.log(res);
      this.toastr.warning("OTP Resend ");
      // this.toastr.success(res.otpData.userToken)
    });
  }

  onPhoneSubmit() {
    this.ApiService.formPhoneSubmit(this.phoneNumberInputForm.value).subscribe(
      (res: any) => {
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.phoneOTP = res["otpData"].userToken;
          this.phoneNumber = res["otpData"].phone;
          this.phoneApiToken = res["otpData"].apiToken;
          this.steperContainer = !this.steperContainer;
          // this.toastr.success(this.phoneOTP);
        } else {
          this.errorMessagePrint(res.message);
        }
      },
      (error) => {}
    );
  }

  errorMessagePrint(message: any) {
    switch (message.code) {
      case 21211: {
        this.toastr.warning("Invalid Phone Number");
        break;
      }
      case 21212: {
        this.toastr.warning("Invalid Sender Phone Number");
        break;
      }
      case 21214: {
        this.toastr.warning("Phone Number Cannot be Reached");
        break;
      }
      case 21216: {
        this.toastr.warning("Phone Number Is Blocked by Service Provider");
        break;
      }
      default: {
        this.toastr.warning(message);
        break;
      }
    }
  }

  onOtpSubmit() {
    this.ApiService.formOtpSubmit(
      this.otp,
      this.phoneNumber,
      this.phoneApiToken
    ).subscribe(
      (res: any) => {
        if (res.success == 1) {
          this.toastr.success(res.message);
          this.registerStep = !this.registerStep;
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {}
    );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
