import { Component, OnInit, OnDestroy } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { FaStackItemSizeDirective } from "@fortawesome/angular-fontawesome";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-promo-code",
  templateUrl: "./promo-code.component.html",
  styleUrls: ["./promo-code.component.scss"],
})
export class PromoCodeComponent implements OnInit, OnDestroy {
  userDetails: any;
  userName: any;
  deliveryAddress: any;
  OrderType: any;
  openPromoPanel: boolean;
  cartAmount: any;
  metaData: any;
  paymentMethod: any;
  isOrderNotesEnabled = true;
  isOnlinePaymentEnabled = false;
  isCashOnDeliveryEnabled = false;
  isCardOnDeliveryEnabled = false;
  isGatewayEnabled = false;
  cartOrder: any;
  promoCodeText: any;
  orderNotes: any = "";
  orderId: any;
  paymentGateway: boolean = false;
  paymentUrl: any;
  ngLoader: boolean = false;
  appliedPromoCode: any;
  discountedPrice: any;
  promoDiscount: number = 0;
  subTotal: any;
  orderTotal: any;
  promoCode: any;
  nopaymentMethod: boolean = false;
  formBody = {};
  loading = true;
  emirates: any;
  deliveryCharge: any;
  cartItems: any = [];
  preparationTimeMinutes = 0;
  restaurantSlug = "jijos-restaurant-1";
  addressId = null;
  calculatedAmounts: any = {
    subTotal: 0,
    deliveryCharge: 0,
    discount: 0,
    promoDiscount: 0,
    finalTotal: 0,
  };
  showLoader() {
    this.loading = true;
  }
  hideLoader() {
    this.loading = false;
  }
  formatPrice(value) {
    return Number(value).toFixed(2);
  }

  constructor(
    public ApiService: ApiService,
    private route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
    private title: Title
  ) {}

  promoModal() {
    this.openPromoPanel = !this.openPromoPanel;
  }
  removePromoCode() {
    let promoValue: any = document.querySelector(".promoValue");
    promoValue.value = null;
    this.promoCode = null;
    this.ApiService.removeLocalStorageItem("promoCode");
    this.promoDiscount = null;
    //re-calling calculate api
    let data = {
      items: this.cartItems,
      restaurantSlug: this.restaurantSlug,
      addressId: this.addressId,
    };

    this.calculatedAmounts.finalTotal =
      this.calculatedAmounts.finalTotal + this.calculatedAmounts.promoDiscount;
    this.calculatedAmounts.promoDiscount = 0;
    // this.ApiService.calculateSubTotalAmount(data).subscribe((res: any) => {
    //   if (res.success) {
    //     console.log("Calculate API Result is ");
    //     console.log(res);
    //     this.calculatedAmounts.subTotal = res.item.subTotal;
    //     this.calculatedAmounts.deliveryCharge = res.item.deliveryCharge;
    //     this.calculatedAmounts.discount = res.item.discount;
    //     this.calculatedAmounts.promoDiscount = res.item.promoDiscount;
    //     this.calculatedAmounts.finalTotal = res.item.finalTotal;
    //   } else {
    //     this.toastr.warning(res.message);
    //   }
    // });
    //re-calling calculate api end
  }
  handleLogin() {
    var token = this.ApiService.getLocalStorageItem("loginToken");
    if (!token) {
      this.ApiService.goTo("cart", this.router);
    }
  }
  handleEmptyCart() {
    var cartItems = this.ApiService.getLocalStorageItem("cartItem");
    var isCartEmpty = true;
    if (cartItems) {
      cartItems = JSON.parse(cartItems);
      if (cartItems.length) {
        isCartEmpty = false;
        //calculating preparation time
        if (Array.isArray(cartItems) && cartItems.length) {
          cartItems.forEach((detailsItem) => {
            if (
              detailsItem.preparationTimeMinutes &&
              this.preparationTimeMinutes <
                Number(detailsItem.preparationTimeMinutes)
            ) {
              this.preparationTimeMinutes = Number(
                detailsItem.preparationTimeMinutes
              );
            }
          });
        }
      }
    }
    if (isCartEmpty) {
      this.ApiService.goTo("cart", this.router);
    }
    this.cartItems = cartItems;
  }
  init() {
    var that = this;
    this.handleLogin();
    this.handleEmptyCart();
    this.loadMeta(function () {
      that.initData();
    });
  }

  ngOnInit() {
    this.ApiService.setRestaurantSlug(
      this.route,
      (fn) => {
        this.init();
      },
      null,
      this.title,
      null,
      "Confirm order"
    );
  }
  ngOnDestroy() {
    this.ApiService.removeLocalStorageItem("paymentMethod");
  }

  /** */

  loadMeta(cb) {
    this.showLoader();
    let meta = {};
    this.ApiService.getRestaurantMetaData().subscribe((data: any) => {
      console.log("Meta data is ");
      console.log(JSON.stringify(data));
      if (data.success) {
        var item = data.item;
        meta = data.item;
        var emirates = item.emirates;
        var defDeliveryCharge = item.defaultDeliveryCharge
          ? item.defaultDeliveryCharge
          : 0;
        this.deliveryCharge = defDeliveryCharge;
        console.log("Default Delivery charge is " + defDeliveryCharge);
        this.emirates = emirates;
        var deliveryAddress: any =
          this.ApiService.getLocalStorageItem("deliveryAddress");
        if (deliveryAddress) {
          deliveryAddress = JSON.parse(deliveryAddress);
          if (deliveryAddress) {
            var emiratesId = deliveryAddress.emirateId;
            console.log("Emirates id is " + emiratesId);
            for (var emirate of emirates) {
              console.log("Checking " + emirate.id + " == " + emiratesId);
              if (emirate.id == emiratesId) {
                var deliveryCharge = emirate.staticCharges
                  ? emirate.staticCharges
                  : 0;
                if (deliveryCharge) {
                  this.deliveryCharge = deliveryCharge;
                }
                console.log("Delivery charge is " + deliveryCharge);
              }
            }
          }
        }
        console.log("Delivery charge is still " + this.deliveryCharge);
        for (var prop in item) {
          if (this[prop] !== undefined) {
            console.log(
              "Setting property  " + prop + " as value " + item[prop]
            );
            this[prop] = item[prop];
          }
        }
        console.log("Delivery charge is again ");
        console.log(this.deliveryCharge);
      }
      this.hideLoader();
      cb(meta);
    });
  }
  initData() {
    this.loadMeta((metaData) => {
      console.log("Meta data received is ");
      console.log(metaData);
      this.metaData = metaData;
      if (metaData.paymentGatewaySettings) {
        this.isGatewayEnabled = metaData.paymentGatewaySettings.isEnabled;
      }
      this.isOrderNotesEnabled = metaData.isOrderNotesEnabled;
      this.promoCode = this.ApiService.getLocalStorageItem("promoCode");
      if (this.promoCode) {
        this.onApplyPromoCode();
      }
      this.userDetails = JSON.parse(
        this.ApiService.getLocalStorageItem("loginToken")
      );
      this.userName = this.userDetails.value.name;

      /* this.metaData = JSON.parse(
        this.ApiService.getLocalStorageItem("metaData")
      ); */
      this.cartAmount = JSON.parse(
        this.ApiService.getLocalStorageItem("cartAmount")
      );

      this.OrderType = this.ApiService.getLocalStorageItem("OrderType");
      this.cartOrder = JSON.parse(
        this.ApiService.getLocalStorageItem("cartItem")
      );
      this.paymentMethod = this.ApiService.getLocalStorageItem("paymentMethod");
      if (this.paymentMethod) {
        this.paymentMethod = JSON.parse(this.paymentMethod);
      }
      var deliveryAddress =
        this.ApiService.getLocalStorageItem("deliveryAddress");
      if (deliveryAddress) {
        deliveryAddress = JSON.parse(deliveryAddress);
      }
      this.deliveryAddress = deliveryAddress;
      var isPaymentMethodInvalid =
        (!this.isOnlinePaymentEnabled &&
          this.paymentMethod == "online_Payment") ||
        (!this.isCashOnDeliveryEnabled &&
          this.paymentMethod == "Cash_Delivery") ||
        (!this.isCardOnDeliveryEnabled &&
          this.paymentMethod == "Card_Delivery");
      console.log("payment method is " + this.paymentMethod);
      if (isPaymentMethodInvalid) {
        console.log("Payment method is invalid...");
        this.paymentMethod = null;
      }
      console.log("About to call calculate api");
      //Calculate api
      let orderingMethod = this.ApiService.getLocalStorageItem("OrderType");
      if (orderingMethod == "deliver_address") {
        let deliveryAddressLocalStorage =
          this.ApiService.getLocalStorageItem("deliveryAddress");
        if (deliveryAddressLocalStorage)
          this.addressId = JSON.parse(deliveryAddressLocalStorage).id ?? null;
      }

      let data = {
        items: this.cartItems,
        restaurantSlug: this.restaurantSlug,
        addressId: this.addressId,
        promoCode: this.promoCode,
      };

      this.ApiService.calculateSubTotalAmount(data).subscribe((res: any) => {
        if (res.success) {
          console.log("Calculate API Result is ");
          console.log(res);
          this.calculatedAmounts.subTotal = res.item.subTotal;
          this.calculatedAmounts.deliveryCharge = res.item.deliveryCharge;
          this.calculatedAmounts.discount = res.item.discount;
          this.calculatedAmounts.promoDiscount = res.item.promoDiscount;
          this.calculatedAmounts.finalTotal = res.item.finalTotal;
          this.promoCode = res.item.promoCode ? res.item.promoCode : null;
        } else {
          this.toastr.warning(res.message);
          if (res.message == "Promo code maximum used users count exceeded") {
            // window.prompt("Promo code maximum used users count exceeded");
            console.log("-----------IF PART EXECUTED ----------------");
            localStorage.removeItem("qwertonerest__promoCode");
            // this.ngOnInit();
            return;
          }
          // this.ApiService.goTo(`cart`, this.router);//suppose if min order amount validation failed
        }
      });
    });
  }
  /** */

  ngDoCheck() {
    var cartTotal = this.cartAmount ? this.cartAmount.total : 0;
    this.subTotal = cartTotal - this.promoDiscount;
    this.orderTotal =
      parseFloat(this.subTotal) + parseFloat(this.deliveryCharge);
  }

  promoCodeValue(v) {
    this.promoCodeText = v.target.value;
  }
  onApplyPromoCode() {
    const code = this.promoCodeText ? this.promoCodeText.toLowerCase() : "";
    let body: any = {
      code,
      id: this.metaData.id,
      items: this.cartOrder,
      //addressId: this.deliveryAddress.id
    };
    var deliveryAddress: any =
      this.ApiService.getLocalStorageItem("deliveryAddress");
    if (deliveryAddress) {
      deliveryAddress = JSON.parse(deliveryAddress);
    }

    if (deliveryAddress) {
      body.addressId = deliveryAddress.id;
    }
    console.log(body);
    this.ngLoader = true;
    this.ApiService.applyPromoCode(body).subscribe((res: any) => {
      this.ngLoader = false;
      console.log(res);
      if (res.success == 1) {
        // this.discountedPrice = res.discountedPrice;
        // this.promoDiscount = res.promoDiscount;

        //re-calling calculate api
        const promoCode = this.promoCodeText
          ? this.promoCodeText.toLowerCase()
          : "";
        let data = {
          items: this.cartItems,
          restaurantSlug: this.restaurantSlug,
          addressId: this.addressId,
          promoCode,
        };
        this.ApiService.calculateSubTotalAmount(data).subscribe(
          (calculateTotalAmountResponse: any) => {
            if (calculateTotalAmountResponse.success) {
              this.promoCode = this.promoCodeText;
              console.log("Calculate API Result is ");
              console.log(res);
              this.calculatedAmounts.subTotal =
                calculateTotalAmountResponse.item.subTotal;
              this.calculatedAmounts.deliveryCharge =
                calculateTotalAmountResponse.item.deliveryCharge;
              this.calculatedAmounts.discount =
                calculateTotalAmountResponse.item.discount;
              this.calculatedAmounts.promoDiscount =
                calculateTotalAmountResponse.item.promoDiscount;
              this.calculatedAmounts.finalTotal =
                calculateTotalAmountResponse.item.finalTotal;
              this.promoCode = calculateTotalAmountResponse.item.promoCode
                ? calculateTotalAmountResponse.item.promoCode
                : null;
              this.ApiService.setLocalStorageItem("promoCode", this.promoCode);
              this.appliedPromoCode = res.message;
              this.toastr.success(res.message);
            } else {
              this.toastr.warning(calculateTotalAmountResponse.message);
              // this.ApiService.goTo(`cart`, this.router);//suppose if min order amount validation failed
            }
          }
        );
        //re-calling calculate api end
        this.openPromoPanel = false;
      } else {
        this.ApiService.removeLocalStorageItem("promoCode");
        this.toastr.warning(res.message);
      }
    });
  }

  ////////////////////////////create order////////////////////////////////////////

  orderSubmit() {
    // if (!this.ApiService.getLocalStorageItem("paymentMethod")) {
    //   this.nopaymentMethod = true;
    //   return true;
    // }

    if (!this.paymentMethod) {
      this.nopaymentMethod = true;
      return true;
    }
    if (this.paymentMethod == "Card_Delivery") {
      var isCardOnDeliveryEnabled = true,
        isOnlinePaymentEnabled = false,
        isCashOnDeliveryEnabled = false;
    } else if (this.paymentMethod == "online_Payment") {
      var isCardOnDeliveryEnabled = false,
        isOnlinePaymentEnabled = true,
        isCashOnDeliveryEnabled = false;
    } else if (this.paymentMethod == "Cash_Delivery") {
      var isCardOnDeliveryEnabled = false,
        isOnlinePaymentEnabled = false,
        isCashOnDeliveryEnabled = true;
    }
    if (this.OrderType == "Dine_in") {
      var isDineInEnabled = true,
        isHomeDeliveryEnabled = false,
        isTakeAwayEnabled = false;
    } else if (this.OrderType == "get_from") {
      var isDineInEnabled = false,
        isHomeDeliveryEnabled = false,
        isTakeAwayEnabled = true;
    } else if (this.OrderType == "deliver_address") {
      var isDineInEnabled = false,
        isHomeDeliveryEnabled = true,
        isTakeAwayEnabled = false;
      console.log("Address id is ");
      console.log(this.deliveryAddress.id);
    }

    var formBody: any = {
      id: this.metaData.id,
      items: this.cartOrder,
      preparationTimeMinutes: this.preparationTimeMinutes,
      promoCode: this.promoCode,
      orderNotes: this.orderNotes || "",
      isCardOnDelivery: isCardOnDeliveryEnabled,
      isOnlinePayment: isOnlinePaymentEnabled,
      isCashOnDelivery: isCashOnDeliveryEnabled,
      isDineIn: isDineInEnabled,
      isHomeDelivery: isHomeDeliveryEnabled,
      isTakeAway: isTakeAwayEnabled,
    };
    if (this.deliveryAddress) {
      formBody.addressId = this.deliveryAddress.id;
    }
    this.formBody = formBody;

    this.ngLoader = true;
    console.log("Calling api...");
    console.log("Form body is ");
    console.log(formBody);
    this.ApiService.orderSubmitApi(this.formBody).subscribe((res: any) => {
      this.ngLoader = false;
      console.log(res);
      if (res.success == 1) {
        this.orderId = res["orderId"];
        // this.toastr.success(res.message);
        if (res.isOnlinePayment == true) {
          this.ngLoader = true;
          this.ApiService.paymentApi(this.orderId).subscribe((res: any) => {
            this.ngLoader = false;
            console.log(res);
            if (res.success == 1) {
              console.log(res["paymentPageUrl"]);

              this.paymentUrl = res["paymentPageUrl"];
              window.location.href = this.paymentUrl;
              console.log("Payment page api result received...");
              //  this.clearlocalStorage()
            } else {
              this.toastr.warning(res.message);
            }
          });
        } else {
          this.clearlocalStorage();
          console.log("Order id is " + this.orderId);
          this.ApiService.goTo(`order-summary/${this.orderId}`, this.router);
        }
      } else {
        this.toastr.warning(res.message);
      }
    });
  }

  paymentselection(e: any, val: any) {
    console.log("executed");
    console.log(e.target.previousSibling.checked);
    e.target.previousSibling.checked = true;
    this.nopaymentMethod = false;
    this.paymentMethod = val;
    this.ApiService.setLocalStorageItem("paymentMethod", JSON.stringify(val));
  }

  paymentselection1(val: any) {
    this.paymentMethod = val;
    this.nopaymentMethod = false;
    this.ApiService.setLocalStorageItem("paymentMethod", JSON.stringify(val));
  }

  clearlocalStorage() {
    this.ApiService.removeLocalStorageItem("cartAmount");
    this.ApiService.removeLocalStorageItem("cartItem");
    this.ApiService.removeLocalStorageItem("promoCode");
    this.ApiService.removeLocalStorageItem("deliveryAddress");
    this.ApiService.removeLocalStorageItem("OrderType");
    this.ApiService.removeLocalStorageItem("paymentMethod");
  }
  loadTotalData = () => {
    this.init();
  };
}
