import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Title} from "@angular/platform-browser"; 

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.scss']
})
export class OrderReportComponent implements OnInit {

  orderId: any;
  reportDetails: any;
  isOnlinePayment:any;
  address: any;
  ngLoader:boolean = false;  
  constructor(private ApiService: ApiService, private route: ActivatedRoute, private router: Router, private title: Title) { }
  goToTrackOrder() {
    this.ApiService.goTo(`/track-order/${this.orderId}`,this.router)
  }
  formatPrice(value) {
    return Number(value).toFixed(2);
  }
  goTo(url) {
    this.ApiService.goTo(url,this.router)
  }
  ngOnInit() {
    this.ApiService.setRestaurantSlug(this.route,fn => { 
      this.init()
    }, null, this.title, null, "Order details");   
  }
  init() {
    this.orderId = this.route.snapshot.paramMap.get('id')
    this.ngLoader = true
    this.ApiService.orderReportApi(this.orderId).subscribe((res:any)=>{
      this.ngLoader = false     
      this.reportDetails = res['item']
      var address = this.reportDetails.address;
      if(address) {
        var addressLine =  address.addressLine;
        var deliveryArea = null;
        var emirate = null;
        if(address.deliveryArea) {
          deliveryArea = address.deliveryArea.name;
        }
        if(address.emirates) {
          emirate = address.emirates.name;
        }
        var addressData :any = [addressLine, deliveryArea, emirate];
        addressData = addressData.filter(function (el) {
          return el != null;
        });
        addressData = addressData.join(', ');
        console.log("Address data "+addressData);
        this.address = addressData;
      }
       console.log(res);
    })
  }

}

