import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from "@angular/router";
import { BrowserModule, DomSanitizer, Title } from "@angular/platform-browser";
import { idText } from "typescript";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  imageBase: any;
  about;
  images = [];
  specialTimings = {
    dateRange: {},
    values: []
  };
  normalTimings = {
    values: []
  };

  imagePath: any;
  latitude = 51.678418;
  longitude = 7.809007;
  locationChosen = false;
  restaurant :any = 
  {
    name: null,
    logo: null,
    storeImage: "",
    logoImage:"",
    timings: [],
  };
  aboutPageDescription: any;
  ngLoader = false;
  specialStart :any = null ;
  specialEnd :any = null;
  currentDate :any = new Date();
  constructor(
    private router: Router,
    private ApiService: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private title: Title
  ) {}

  // onChoseLocation(event) {
  //   this.latitude = event.coords.lat;
  //   this.longitude = event.coords.lng;
  //   this.locationChosen = true;
  // }
  ngOnInit() {
    
    
    this.ngLoader = true;
    this.ApiService.setRestaurantSlug(this.route, (fn) => {
      this.ngLoader = false;
      this.init();
    }, null, this.title, "about" );
  }
  goTo(url) {
    this.ApiService.goTo(url, this.router);
  }
  init() {
    this.ngLoader = true;
    this.ApiService.getAbout().subscribe((data: any) => {
      // var dd = String(this.currentDate.getDate()).padStart(2, '0');
    // var dd = 10;
    // var mm = String(this.currentDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = this.currentDate.getFullYear();
    // this.currentDate =  mm + '/' + dd + '/' + yyyy;
    this.currentDate = Date.now();
    console.log(this.currentDate);
      console.log("ABOUT DATA");
      console.log(data);
      console.log(isNullOrUndefined(data.item.timeAvailability.specialTimings.availableDateRange));
      
      if(!isNullOrUndefined(data.item.timeAvailability.specialTimings.availableDateRange))
      {
        this.specialStart = data.item.timeAvailability.specialTimings.availableDateRange.startDate
        console.log( typeof(this.specialStart));
      }
      
    
      if(!isNullOrUndefined(data.item.timeAvailability.specialTimings.availableDateRange) )
      {
        this.specialEnd = data.item.timeAvailability.specialTimings.availableDateRange.endDate
      }
      
      if(new Date(this.specialStart) <  new Date(this.currentDate) &&  new Date(this.specialEnd) <  new Date(this.currentDate) )
      {
            console.log("IF EXECUTETED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");     
      }
      else
      {
        console.log("ELSE EXECUTETED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      }
      console.log("Whatsapp number is " + data.item.whatsappNumber);
      this.ngLoader = false;
      console.log("About api result is");
      console.log(data);
      var imageBase = data["imageBase"];
      console.log("image base is " + imageBase);
      var restaurant: any = data.item;
      var logo = "assets/img/logo-icon.png";
      if (restaurant.logoImage) {
        logo = `${imageBase}${restaurant.logoImage}`;
      }
      restaurant.logoImage = logo;
      this.restaurant.logoImage = logo //new
      this.restaurant.aboutPageTitle = restaurant.aboutPageTitle; //new
     if (restaurant.aboutPageDescription) {
        this.aboutPageDescription = this.sanitizer.bypassSecurityTrustHtml(
          restaurant.aboutPageDescription
        );
      }
      console.log(this.restaurant.aboutPageTitle);
      console.log(this.aboutPageDescription);
       
      
      this.restaurant.displayAddress = restaurant.displayAddress; //new
      this.restaurant.cuisines = restaurant.cuisines; //new
      this.restaurant.amenities = restaurant.amenities //new
      this.restaurant.contactEmail = restaurant.contactEmail //new
      this.restaurant.contactPhone = restaurant.contactPhone //new
      this.restaurant.whatsappNumber = restaurant.whatsappNumber //new
      if (restaurant.storeImage) {
        var storeImage = `${imageBase}${restaurant.storeImage}`;
        restaurant.storeImage = storeImage;
        this.restaurant.storeImage = storeImage //new
        console.log("STORE IMAGES!!!!!!!!!!!!!!!!");
        console.log(storeImage);       
      } else {
        this.restaurant.storeImage = "assets/img/img3.png";
      }

     // this.restaurant.tagline = restaurant.seoAboutPageMeta.aboutPageTitle;
    //  console.log("tagline..................."+restaurant.seoAboutPageMeta.aboutPageTitle);    
      this.restaurant.name = restaurant.name
      console.log(restaurant.logoImage);
      let timing = restaurant.timing? restaurant.timing: {normal: {values:[]}, special: {values: [], dateRange: {startDate: null, endDate: null}}}
      
      if(!isNullOrUndefined(restaurant.timing.normal))
      {
        this.normalTimings.values = timing.normal.values;
      }
      if(!isNullOrUndefined(restaurant.timing.special))
      {
        this.specialTimings.values = timing.special.values;
        this.specialTimings.dateRange = timing.special.dateRange;
      }
     
     
      console.log('Normal timings are');
      console.log(this.normalTimings);

      // if (restaurant.aboutPageDescription) {
      //   this.aboutPageDescription = this.sanitizer.bypassSecurityTrustHtml(
      //     restaurant.aboutPageDescription
      //   );
      // }
      if (restaurant.seoAboutPageMeta) {
        var aboutPage = restaurant.seoAboutPageMeta;
        if (aboutPage.aboutPageTitle) {
          restaurant.tagline = aboutPage.aboutPageTitle;
          this.restaurant.tagline = aboutPage.aboutPageTitle;      //new  
        }
      }
      // this.imagePath =this.about['images'][0]
      // this.images.push({
      //   path:  this.imageBase + this.imagePath
      // });
      // this.restaurant = restaurant;
      console.log(this.restaurant)
      
      
    });
  }
}
