import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { User } from "./user.model";
import { BASE_URL } from "../../environments/api";
import { BehaviorSubject } from "rxjs";
import * as jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  UserToken: string;
  decodedToken: any;
  tokenExpTime: any;
  slug: string;
  noRestaurant: any = false;
  tokenData: Object;
  infoPageDetails: any;
  static userLogedIn() {
    throw new Error("Method not implemented.");
  }

  private relatedItemSource = new BehaviorSubject<string>("");
  relatedItem = this.relatedItemSource.asObservable();

  relatedProductItemFilter(relatedItem: string) {
    this.relatedItemSource.next(relatedItem);
  }
  Set_Restaurant_Name = null;
  POST_LOGIN = null;
  POST_PHONE_NUMBER = null;
  POST_OTP_VERIFY = null;
  POST_REGISTER = null;
  POST_ADD_ADDRESS = null;
  DELETE_ADD_ADDRESS = null;
  GET_ADDRESS_LIST = null;
  GET_ADDRESS_DETAIL = null;
  EDIT_ADDRESS = null;
  POST_ADD_CARD_ = null;
  GET_CARD_LIST = null;
  DELETE_CARD = null;
  GET_EMIRATES_LIST = null;
  GET_AREA_LIST = null;
  META_DATA = null;
  GET_SPALSH = null;
  GET_PRODUCT_LIST = null;
  GET_CATEGORIES_LIST = null;
  GET_ABOUT = null;
  GET_INFO_PAGES_LIST = null;
  GET_INFO_PAGES_DETAIL = null;
  GET_PRODUCT_DETAILS = null;
  CALCULATE_SUBTOTAL = null;
  CART_ORDER = null;
  PROMO_CODE = null;
  OFFER_CODE = null;
  PAYMENT = null;
  ORDER_REPORT = null;
  ORDER_TRACK = null;
  CHECK_USER_EXISTS = null;
  ORDER_ACTIVE = null;
  ORDER_ACTIVE_COUNT = null;
  FORGOT_PASSWORD = null;
  VERIFY_OTP = null;
  RESET_PASSWORD = null;

  isVegOnlyFilterEnabled: boolean;

  showActiveOrder: boolean = false;
  isActiveOrderLive: boolean = false;

  constructor(private http: HttpClient) {}
  userLogedIn;
  httpOptions;

  // paymentGateWayCall(orderId) {
  //   return this.http.post('https://www.paytabs.com/apiv2/create_pay_page/',orderId).pipe(
  //     map(response=>{
  //       console.log(response)
  //     })
  //   );
  // }

  getProductList(id, slug, tag, keyword) {
    var params: any = {
      slug: slug,
      tag: tag,
      keyword: keyword,
      page: "",
      perPage: "",
    };
    if (id) {
      params.categoryId = id;
    }
    return this.http.get(this.GET_PRODUCT_LIST, {
      params: params,
    });
  }
  getSplashScreen() {
    console.log("Splash path is " + this.GET_SPALSH);
    return this.http.get(this.GET_SPALSH);
  }
  getCategoriesList() {
    return this.http.get(this.GET_CATEGORIES_LIST);
  }

  getProductDetails(id) {
    return this.http.get(
      this.GET_PRODUCT_DETAILS + "/" + id + "?relatedItemsCount=5"
    );
  }
  getAbout() {
    console.log("Calling about api " + this.GET_ABOUT);
    return this.http.get(this.GET_ABOUT);
  }
  getInfoPages() {
    console.log("Calling info page api " + this.GET_INFO_PAGES_LIST);
    return this.http.get(this.GET_INFO_PAGES_LIST);
  }
  getInfoPageDetail(slug) {
    console.log("Calling info page detail api " + this.GET_INFO_PAGES_DETAIL);
    console.log("Page slug", slug);
    return this.http.get(this.GET_INFO_PAGES_DETAIL + `${slug}`);
  }
  getRestaurantMetaData() {
    console.log("3333333333333333333333333");
    console.log("Meta data url is ", this.META_DATA);
    return this.http.get(this.META_DATA);
  }

  getAllActiveOrders(userId: any, restId: any, active: any) {
    return this.http.get(
      this.ORDER_ACTIVE +
        "?" +
        "userId=" +
        userId +
        "&restId=" +
        restId +
        "&active=" +
        active.active
    );
  }

  getAllActiveOrdersCount(userId: any, restId: any) {
    return this.http.get(
      this.ORDER_ACTIVE_COUNT + "?" + "userId=" + userId + "&restId=" + restId
    );
  }
  getDynamicScript(cb) {
    this.getRestaurantMetaData().subscribe(
      (meta: any) => {
        console.log("111111111111111111111111");
        console.log("Meta is this", meta);
        localStorage.setItem("restaurantId", meta.item.id);
        this.isVegOnlyFilterEnabled = meta.item.isVegOnlyFilterEnabled;
        console.log(this.isVegOnlyFilterEnabled);

        if (meta && meta.success) {
          meta = meta.item;
          this.removeLocalStorageItem("metaData");
          cb(meta.dynamicScript);
        }
      },
      (err) => {
        console.log("error occured");
        console.log(err);
        this.noRestaurant = true;
      }
    );
  }
  getEmiratesList() {
    return this.http.get(this.GET_EMIRATES_LIST);
  }
  getAreaList(id) {
    return this.http.get(this.GET_AREA_LIST + id + "/delivery-areas");
  }
  addAddress(body) {
    return this.http.post(this.POST_ADD_ADDRESS, body);
  }
  getAddressList() {
    console.log("Address list url is " + this.GET_ADDRESS_LIST);
    return this.http.get(this.GET_ADDRESS_LIST);
  }
  delAddress(id) {
    return this.http.delete(this.DELETE_ADD_ADDRESS + id);
  }
  getAddressDetail(id) {
    return this.http.get(this.GET_ADDRESS_DETAIL + id);
  }
  editAddress(addressId, formData) {
    return this.http.patch(this.EDIT_ADDRESS + addressId, formData);
  }

  addCard(body) {
    return this.http.post(this.POST_ADD_CARD_, body);
  }

  getCardList() {
    return this.http.get(this.GET_CARD_LIST);
  }
  delCard(id) {
    return this.http.delete(this.DELETE_CARD + id);
  }
  getUrl(path) {
    var slug = this.getRestaurantSlug();
    var url = [`${slug}/${path}`];
    return url;
  }
  goTo(path, router, isFullUrl = false, queryParams = null) {
    console.log(path);
    console.log(queryParams);

    var url = !isFullUrl ? this.getUrl(path) : [path];
    if (path.charAt(0) === "/") path = path.slice(1);

    console.log("Url to navigate is " + url);

    if (queryParams) {
      console.log("go to if part executed");
      router.navigate(url, {
        queryParams: queryParams,
        queryParamsHandling: "merge",
      });
    } else {
      console.log("go to else part executed");
      router.navigate(url);
    }
  }
  generateUniqueId() {
    return Date.now() + Math.random();
  }
  setUniqueId(localStorageKey) {
    let uniqueId = this.generateUniqueId();
    this.setLocalStorageItem(localStorageKey, uniqueId);
    return uniqueId.toString();
  }
  getSessionId() {
    let localStorageKey = "session_id";
    let uuid: any = this.getLocalStorageItem(localStorageKey).toString();
    if (!uuid) uuid = this.setUniqueId(localStorageKey);
    return uuid;
  }
  getRestaurantSlug() {
    return this.slug;
  }
  setSlug(slug, cb) {
    if (slug) {
      this.slug = slug;
      this.Set_Restaurant_Name = slug;
      this.POST_LOGIN = `${BASE_URL}/accounts/login`;
      this.POST_PHONE_NUMBER = `${BASE_URL}/accounts/otp`;
      this.POST_OTP_VERIFY = `${BASE_URL}/accounts/otp/verify`;
      this.POST_REGISTER = `${BASE_URL}/accounts/profile`;
      this.POST_ADD_ADDRESS = `${BASE_URL}/accounts/self/address/create`;
      this.DELETE_ADD_ADDRESS = `${BASE_URL}/accounts/self/address/remove/`;
      this.GET_ADDRESS_LIST = `${BASE_URL}/accounts/self/address/list/${slug}`;
      this.GET_ADDRESS_DETAIL = `${BASE_URL}/accounts/self/address/detail/`;
      this.POST_ADD_CARD_ = `${BASE_URL}/accounts/self/cards`;
      this.GET_CARD_LIST = `${BASE_URL}/accounts/self/cards`;
      this.DELETE_CARD = `${BASE_URL}/accounts/self/cards/remove/`;

      this.GET_EMIRATES_LIST = `${BASE_URL}/masters/${slug}/emirates`;
      this.GET_AREA_LIST = `${BASE_URL}/masters/`;
      this.META_DATA = `${BASE_URL}/restaurants/${slug}/meta-data`;
      this.GET_SPALSH = `${BASE_URL}/restaurants/${slug}/splash-screen`;
      this.GET_PRODUCT_LIST = `${BASE_URL}/restaurants/${slug}/catalogue`;
      this.GET_CATEGORIES_LIST = `${BASE_URL}/restaurants/${slug}/categories/list`;
      this.GET_ABOUT = `${BASE_URL}/restaurants/${slug}`;
      this.GET_PRODUCT_DETAILS = `${BASE_URL}/restaurants/${slug}/catalogue`;

      this.CART_ORDER = `${BASE_URL}/orders/restaurant/${slug}/create`;
      this.PROMO_CODE = `${BASE_URL}/orders/restaurant/${slug}/promo`;
      this.OFFER_CODE = `${BASE_URL}/orders/restaurant/${slug}/offers/list`;
      this.PAYMENT = `${BASE_URL}/payments/restaurant/${slug}`;
      this.ORDER_REPORT = `${BASE_URL}/orders/`;
      this.ORDER_ACTIVE = `${BASE_URL}/orders/activeOrders`;
      this.ORDER_ACTIVE_COUNT = `${BASE_URL}/orders/activeOrdersCount`;
      this.ORDER_TRACK = `${BASE_URL}/orders/restaurant/${slug}/status-history/`;

      this.FORGOT_PASSWORD = `${BASE_URL}/accounts/send-otp/`;
      this.VERIFY_OTP = `${BASE_URL}/accounts/otp-verify/`;
      this.RESET_PASSWORD = `${BASE_URL}/accounts/reset-password/`;

      this.EDIT_ADDRESS = `${BASE_URL}/accounts/self/address/update/`;
      this.GET_INFO_PAGES_LIST = `${BASE_URL}/restaurants/${slug}/pages/list-pages`;
      this.GET_INFO_PAGES_DETAIL = `${BASE_URL}/restaurants/${slug}/pages/`;
      this.CALCULATE_SUBTOTAL = `${BASE_URL}/orders/restaurant/${slug}/calculate-subtotal`;
      this.CHECK_USER_EXISTS = `${BASE_URL}/accounts/check-user-exists`;
    }
    cb();
  }
  setRestaurantSlug(
    activeRoute = null,
    cb,
    slug = null,
    title = null,
    page = "home",
    titlePrefix = ""
  ) {
    let onAfter = () => {
      if (title) this.setRestaurantInfo(title, page, titlePrefix);
      cb();
    };
    if (activeRoute)
      activeRoute.params.subscribe((params) => {
        slug = params["restaurant-slug"];
        this.setSlug(slug, onAfter);
      });
    else this.setSlug(slug, onAfter);
  }

  setRestaurantInfo(title, page = null, titlePrefix = "") {
    page = !page ? "home" : page;
    this.getMeta((meta) => {
      let seoFields = {
        home: {
          key: "seoHomePageMeta",
        },
        about: {
          key: "seoAboutPageMeta",
        },
      };
      let seoParams = meta[seoFields[page].key];
      let pageTitle = meta.name;
      if (seoParams) pageTitle = seoParams.pageTitle || pageTitle;

      pageTitle = !titlePrefix ? pageTitle : `${titlePrefix} | ${pageTitle}`;

      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon && seoParams?.seoImage) {
        favicon.setAttribute(
          "href",
          `https://eato.ae/images/${seoParams?.seoImage}`
        );
      }

      title.setTitle(pageTitle);
    });
  }
  getActualLocalStorageKey(key) {
    if (
      key == "session_id" ||
      key == "loginToken" ||
      key == "restaurantSlug" ||
      key == "resetToken"
    )
      return key;
    let slug = this.getRestaurantSlug();
    return `${slug}__${key}`;
  }
  getLocalStorageItem(key) {
    key = this.getActualLocalStorageKey(key);
    // console.log(`Finding value for localstorage item ${key}`);
    return localStorage.getItem(key);
  }
  setLocalStorageItem(key, value) {
    key = this.getActualLocalStorageKey(key);
    localStorage.setItem(key, value);
  }
  removeLocalStorageItem(key) {
    key = this.getActualLocalStorageKey(key);
    localStorage.removeItem(key);
  }
  logout() {
    this.removeLocalStorageItem("loginToken");
    this.removeLocalStorageItem("resetToken");
  }
  registerUser(user: User) {
    const body: User = {
      name: user.name,
      password: user.password,
      email: user.email,
      countryCode: user.countryCode,
      phone: user.phone,
      restaurantSlug: user.restaurantSlug,
    };
    console.log("In register user api function");
    console.log(body);
    return this.http.post(this.POST_REGISTER, body).pipe(
      map((response) => {
        if (response["success"] == 1) {
          console.log(response);
          this.UserToken = response["token"];
          this.decodedToken = jwt_decode(this.UserToken, "food_book");
          this.tokenExpTime = this.decodedToken.exp;
          this.tokenData = this.decodedToken["data"];
          const object = {
            token: this.UserToken,
            value: this.tokenData,
            exp: this.tokenExpTime,
          };
          localStorage.setItem("loginToken", JSON.stringify(object));
        }
        return response;
      })
    );
  }
  getMeta(cb, returnCached = true) {
    let meta = this.getLocalStorageItem("metaData");
    if (returnCached) {
      try {
        meta = meta ? JSON.parse(meta) : null;
      } catch {
        meta = null;
      }
      if (meta) {
        cb(meta);
        return;
      }
    }
    this.getRestaurantMetaData().subscribe((res: any) => {
      console.log("22222222222222222222");

      console.log("Restaurant meta data is ");
      console.log(res);
      if (res.success == 1) {
        meta = { ...res.item, imageBase: res.imageBase };
        this.setLocalStorageItem("metaData", JSON.stringify(meta));
        cb(meta);
      }
    });
  }

  loginSubmit(password, formdata, additionalParams = null) {
    let slug = this.getRestaurantSlug();
    const loginBody = {
      phone: formdata,
      password: password,
      restaurantSlug: slug,
    };
    return this.http.post(this.POST_LOGIN, loginBody).pipe(
      map((response) => {
        if (response["success"] == 1) {
          console.log(response);
          this.UserToken = response["token"];
          this.decodedToken = jwt_decode(this.UserToken, "food_book");
          this.tokenExpTime = this.decodedToken.exp;
          this.tokenData = this.decodedToken["data"];
          const object = {
            token: this.UserToken,
            value: this.tokenData,
            exp: this.tokenExpTime,
          };
          if (additionalParams) {
            for (let index in additionalParams) {
              localStorage.setItem(index, additionalParams[index]);
            }
          }
          localStorage.setItem("loginToken", JSON.stringify(object));
        }
        return response;
      })
    );
  }

  formPhoneSubmit(value) {
    return this.http.post(this.POST_PHONE_NUMBER, value).pipe(
      map((response) => {
        if (response["success"] == 1) {
          // localStorage.setItem('userToken',JSON.stringify(response['token']))
        }
        return response;
      })
    );
  }

  formOtpSubmit(otp, phone, token) {
    const OTP_body = {
      otp: otp,
      phone: phone,
      token: token,
    };
    return this.http.post(this.POST_OTP_VERIFY, OTP_body).pipe(
      map((response) => {
        if (response["success"] == 1) {
          // localStorage.setItem('userToken',JSON.stringify(response['token']))
        }
        return response;
      })
    );
  }

  forgotPassword(data) {
    return this.http.post(this.FORGOT_PASSWORD, data);
  }
  verifyOtp(data) {
    return this.http.post(this.VERIFY_OTP, data);
  }
  resetPassword(data) {
    return this.http.post(this.RESET_PASSWORD, data);
  }

  loggedIn() {
    return !!this.getLocalStorageItem("loginToken");
  }
  calculateSubTotalAmount(data) {
    return this.http.post(this.CALCULATE_SUBTOTAL, data);
  }
  orderSubmitApi(data) {
    return this.http.post(this.CART_ORDER, data);
  }
  applyPromoCode(data) {
    return this.http.post(this.PROMO_CODE, data);
  }

  offerCodeApi() {
    return this.http.get(this.OFFER_CODE);
  }
  paymentApi(orderId) {
    console.log("PAYMENTTTT !!!!!!!!!!!!!!!!!!!!!!!!!!");
    console.log(this.PAYMENT);
    return this.http.get(this.PAYMENT, {
      params: {
        orderId: orderId,
      },
    });
  }
  checkUserExists(email, phone) {
    var url = "";
    if (email) {
      url = this.CHECK_USER_EXISTS + "?email=" + email;
    }
    if (phone) {
      url = this.CHECK_USER_EXISTS + "?phone=" + phone;
    }
    return this.http.get(url);
  }
  orderReportApi(orderId) {
    return this.http.get(
      `${this.ORDER_REPORT}${this.Set_Restaurant_Name}/${orderId}/report`
    );
  }
  orderTrack(orderId) {
    return this.http.get(this.ORDER_TRACK + orderId);
  }

  getUnixTsInSecondsFromDate(dateStr) {
    return Math.floor(Date.parse(dateStr) / 1000) || 0;
  }
  getUnixTsInSecondsFromDateObj(date) {
    return Math.floor(date.getTime() / 1000);
  }
  getDateFromUnixTsInSeconds(unixTsInSeconds) {
    return new Date(unixTsInSeconds * 1000);
  }
  getDateStrFromUnixTsInSeconds(unixTsInSeconds) {
    console.log("Date is", this.getDateFromUnixTsInSeconds(unixTsInSeconds));
    return this.getDateFromUnixTsInSeconds(unixTsInSeconds)
      .toISOString()
      .slice(0, 10);
  }
  addDateStrsFromUnixTs(obj) {
    obj.startDate = obj.startUnixTsSeconds
      ? this.getDateStrFromUnixTsInSeconds(obj.startUnixTsSeconds)
      : null;
    obj.endDate = obj.endUnixTsSeconds
      ? this.getDateStrFromUnixTsInSeconds(obj.endUnixTsSeconds)
      : null;
    return obj;
  }
  addDatesFromUnixTs(obj) {
    obj.startDate = obj.startUnixTsSeconds
      ? this.getDateFromUnixTsInSeconds(obj.startUnixTsSeconds)
      : null;
    obj.endDate = obj.endUnixTsSeconds
      ? this.getDateFromUnixTsInSeconds(obj.endUnixTsSeconds)
      : null;
    return obj;
  }
  addUnixTsToRequestBody(requestBody) {
    requestBody.startDate.setHours(0, 0, 0, 0);
    requestBody.endDate.setHours(23, 59, 0, 0);
    console.log("Unix ts for end date is", requestBody.endDate);
    requestBody.start_unix_ts_seconds = this.getUnixTsInSecondsFromDateObj(
      requestBody.startDate
    );
    requestBody.end_unix_ts_seconds = this.getUnixTsInSecondsFromDateObj(
      requestBody.endDate
    );
    return requestBody;
  }
}
