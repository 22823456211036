import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import {Location} from '@angular/common';
 

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,private ApiService:ApiService, public location: Location){}

  canActivate():boolean{
      //  return true
    if(this.ApiService.loggedIn()){
      return true
    }else{
      let redirectTo = this.location.path();
      console.log("Current URL is " + redirectTo + "  will be redirected to this" )
      var mySubString = redirectTo.split("/");
    console.log(mySubString);
    
      let url :any = mySubString[1]+'/login';
            this.router.navigate([url]);
      // ROUTE IF NO LOGIN CREDENTIALS 
      // this.ApiService.goTo('login', this.router, false, { redirectTo: redirectTo}); 
      return false
    }
  }

  
}
