import { AbstractControl, ValidatorFn, FormControl } from "@angular/forms";

export function passValidator(control: AbstractControl) {
  if (control && (control.value !== null || control.value !== undefined)) {
    const cnfpassValue = control.value;

    const passControl = control.root.get("password"); // magic is this
    if (passControl) {
      const passValue = passControl.value;
      if (passValue !== cnfpassValue || passValue === "") {
        return {
          isError: true,
        };
      }
    }
  }

  return null;
}

export function customRegexValidator(config: any): ValidatorFn {
  return (control: FormControl) => {
    let urlRegEx: RegExp = config.pattern;
    if (control.value && !String(control.value).match(urlRegEx)) {
      return {
        invalidMsg: config.msg,
      };
    } else {
      return null;
    }
  };
}

export function requiredFileType(type: string[]) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      const extension = file.name.split(".")[1].toLowerCase();
      if (type.includes(extension.toLowerCase())) {
        return null;
      } else {
        return {
          requiredFileType: true,
        };
      }
    }
    return null;
  };
}
