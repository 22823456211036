import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Title} from "@angular/platform-browser"; 

@Component({
  selector: 'app-order-placed',
  templateUrl: './order-placed.component.html',
  styleUrls: ['./order-placed.component.scss']
})
export class OrderPlacedComponent implements OnInit {
  orderId: any = "";
  orderReference = "";
  orderReport: any;
  constructor(private ApiService: ApiService, private route: ActivatedRoute, private router: Router, private title: Title) { }
  clearlocalStorage(){
    this.ApiService.removeLocalStorageItem('cartAmount') 
    this.ApiService.removeLocalStorageItem('cartItem') 
    this.ApiService.removeLocalStorageItem('deliveryAddress') 
    this.ApiService.removeLocalStorageItem('OrderType') 
    this.ApiService.removeLocalStorageItem('paymentMethod') 
   
  }
  init() {
    console.log("init called ");
    this.orderId = this.route.snapshot.paramMap.get('id');
    //this.ngLoader = true
    this.clearlocalStorage();
   this.ApiService.setLocalStorageItem('orderId',this.orderId)
    
    this.ApiService.orderReportApi(this.orderId).subscribe((res:any)=>{
      //this.ngLoader = false     
      this.orderReport = res['item'];
      this.orderReference = this.orderReport.referenceNo?this.orderReport.referenceNo:"";
      console.log("Order Report is " + JSON.stringify(this.orderReport));
    })
    //window.location.reload();
  }
  ngOnInit() {
    this.ApiService.setRestaurantSlug(this.route,fn => { 
      this.init()
    }, null, this.title, null, "Order placed");;   
  }
  goToInvoice() {
    this.ApiService.goTo(`invoices/${this.orderId}`,this.router)
  }
  goTo(url) {
    this.ApiService.goTo(url,this.router)
  }
}
